import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'


const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
img {
  
}
`





const GUISSL = () => {
    return <>
          <SheetContainer>
            <h1>uniGUI and SSL</h1>
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default GUISSL;