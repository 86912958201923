import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import {cronSVG} from '../../assets';


const SheetContainer=styled.section`
    width: 95%;
    display: flex;
    flex-direction: column;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    line-height: 40px;
    h1 {
        font-size: 60px;
        text-align: center;
        margin-bottom: 40px
    }
    h2, h3 {
      text-align: center;
      text-decoration: underline;
    }
    img {
      align-self: center;
      margin-bottom: 40px;
    }
    ol, li {
        list-style-type: square;
        font-size: 20px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`

const CronJobs = () => {
  return <>
  <SheetContainer>
     <h1>Automating Tasks with cron Jobs</h1>
     <h3>About Cron</h3>
     <ul>
      <li>Cron is used for scheduling jobs that run in the background within a Unix like system.</li>
      <li>A script must be created for the specific job needed and the path to that script is entered into a crontab which is read by the cron.</li>
      <li>By default cron jobs are executed from the home directory so it is important to include the WHOLE path to the bash script or you could run into your cron job not being excuted as the cron wouldnt know where to look for the bash script file.</li>
      <li>A very specific syntax is followed within the crontab to schedule the date and time of the cronjob. As seen below.</li>
     </ul>
    <img src= { cronSVG } alt="cronjob"></img>
    <h3>How we use cron Jobs</h3>
    <ol>
      <li>First check the status of cron service by using command: <mark>sudo systemctl status cron.service</mark></li>
      <li>If cron is not yet installed use: <mark>apt-get install cron</mark> Then check if running: <mark>systemctl status cron</mark> </li>
      <li>If you sign in as root user you can cd path: <mark>/var/spool/cron/crontabs</mark> to see every crontab by indidvidual users.</li>
      <li>To enter a crontab enter command: <mark>crontab -e</mark> this will open the existing crontab of the user you are signed in as or if there is no existing crontab <br/>for the user this command will create a new crontab for this user. </li>
      <li>If the system has multiple users and you want to enter a specific users crontab enter: <mark>crontab -u username -e</mark></li>
      <li>When a new Crontab is started you will be prompted to choose from a list of text editors, We choose Nano as it is the simplest to work in.</li>
      <li>As mentioned above the cron job syntax within the crontab is very specific, it should look something like this: <mark>* * * * * /bin/bash /path/to/script</mark></li>
      <li><mark>* * * * * sh /path/to/script</mark> - sh may be used in place of /bin/bash. It simply means that the script is a bash script and is run from /bin/bash.</li>
      <li>Try testing your cron job by either adjusting the day and time to run within 5 minutes of your current time or by running <mark>/bin/bash /path/to/script</mark>  in the command line to make sure cron can find your bash script.</li>
    </ol>
    <h3>Troubleshooting</h3>
    <ol>
      <li>Check the cron job syntax, make sure you scheduled it at the time and date you wanted. Make sure you have no extra or misplaced characters. Also make sure your system date and time is correct.</li>
      <li>Make sure the whole path to your script is listed in your cron task or else the cron may not be able to find your script.</li>
      <li>Check cron log to see if job ran at all: <mark>grep CRON /var/log/syslog</mark> To use this command you have to be a root user.</li>
      <li>Check your disk space, tasks may not run if you are low on space. To check available/used space enter command <mark>df -kh</mark></li>
      <li>If you have large overlapping cron jobs scheduled there is a possibility of them failing. Try scheduling them with some space in between jobs.</li>
    </ol>
  </SheetContainer>
  < Footer />
  </>
};

  
  export default CronJobs;