import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'

const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid red;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
li {
  margin: 20px;
  font-size: 20px;
  list-style-type: circle;
}
mark {
  background-color: black;
  color: white;
  padding: 8px;
  font-style: italic;
}
`

const ReactApp = () => {
  return <>
  <SheetContainer>
  <h1>Delphi and Fast Reports</h1>
 <h2>Installing Fast Reports</h2>
    <ol>
        <li>Purchase the license for Fast Reports</li>
        <li>After purchase they will send a confirmation email with the information you need. </li>
        <li>Go to your account, create password.</li>
        <li>Login in to your new account and download the most current version.</li>
        <li>Follow through installation and when prompted for your license key enter the one received after purchase.</li>
        <li>Choose the complete version for installation</li>
        <li>Select the root path, then wait until it has been installed on the computer.</li>
        <li>You will need to compile before running it in Delphi</li>
        <li>Find the folder you chose to install Fast Reports in and run Recompile.exe file as Administrator</li>
        <li>Click Continue</li>
        <li>Choose a compiler- We chose Rad Studio 10.4</li>
        <li>Recompile all packages</li>
        <li>Check the following- with DBE packages <br />-with FireDac packages</li>
        <li>Click Compile and wait until its done.</li>
        <li>Open Delphi, On startup, Delphi will give an error about a new folder inside the delphi directory.</li>
        <li>Click No until Delphi is launched</li>
        <li>On the loading screen you should already be able to see a Fast Reports icon.</li>
        <li>Open Tools » Options » Language » Delphi » Library</li>
        <li>Open Library path and add path to Fast Reports folder, Save.</li>
        <li>Go to Component » Install Packages and click Add.</li>
        <li>For Delphi 10.4 move to folder ...</li>
        <li>This will throw alot of errors, which is normal, it is due to some packages automatically being installed already.</li>
        <h2>Creating a new project</h2>
        <li>Open a new form and on the Palette you should see new categories associated with Fast Reports.</li>
        <li>Found within FastReport VCL category, add TfrxReport to the form.</li>
        <li>Double click on the TfrxReport1 icon and a new report creation window will open.</li>
        <li>Now you can add and edit the report!</li>
    </ol>
  </SheetContainer>
  < Footer />
  </>
};

export default ReactApp;