import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'
import CodeBlock from '../../../components/Codeblock';


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
min-height: 100vh;
padding-bottom: 4.5rem;

header {
    padding: 10px 0;
    text-align: center;
}
main {
    padding: 20px;
    
}
nav li {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
    
}
nav ul li a:hover {
    color: #111;
}

h1, h2 {
  color: black;
  text-align: center;
  text-decoration: underline;
  margin: 20px;
  padding: 20px;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: number;
  line-height: 2.5;
}
p {
  font-size: 22px;
  margin: 20px;
}
`
// const code1 = `
// [http_transport]
// start_port=16384  # Ensure unique port for each HyperServer instance

// [hyper_server]
// binary_name=YourAppBinary  # Main uniGUI application binary
// initial_nodes=2
// max_nodes=8
// persistent_node=1

// [application-0]
// enabled=1
// alias=app1
// binary_name=app1_binary
// port=6080  # Unique port for each app
// persistent_node=1
// `;

  const bashUpdateCommands = `sudo apt-get update
sudo apt-get upgrade`;

  const bashDependenciesCommands = `sudo apt-get install libssl-dev libc6-dev`;

  const bashMoveCommands = `sudo mkdir unigui/
sudo mv HyperServer unigui/
sudo mv YourAppBinary unigui/
sudo chmod +x unigui/HyperServer`;

  const bashConfigCommand = `sudo nano /unigui/hyper_server.cfg`;

  const configFile = `[http_transport]
start_port=16384  # Port range for internal communication between HyperServer and nodes

[hyper_server]
binary_name=YourAppBinary  # Main uniGUI application binary
initial_nodes=2
max_nodes=8
persistent_node=1  # Ensure at least one persistent node
token=**************
framework_files_root=/etc/fmsoft/unigui/unigui_runtime

[application-0]
enabled=1
alias=app1
binary_name=YourAppBinary
port=6060  # Port for external access
persistent_node=1`;

const code2 = `
<VirtualHost *:443>
  ServerName yourdomain.com

  SSLEngine on
  SSLCertificateFile /path/to/cert.pem
  SSLCertificateKeyFile /path/to/privkey.pem

  ProxyPass /app1 http://localhost:Port/
  ProxyPassReverse /app1 http://localhost:Port/
</VirtualHost>
`;

const codeServiceFile = `
[Unit]
Description=uniGUI HyperServer

[Service]
ExecStart=/path/to/your/hyperserver
WorkingDirectory=/path/to/working/directory
Restart=always
User=your_username
Environment=PATH=/usr/bin:/usr/local/bin
Environment=NODE_ENV=development

[Install]
WantedBy=multi-user.target
`;


const codeDisableBackup = 
`dont_create_backup=1
`;



const HyperServer = () => {
  return <>
  <SheetContainer>
  <header>
<h1>uniGUI HyperServer</h1>
</header>
<nav>
<ul>
    <li><a href="#overview">Overview</a></li>
    <li><a href="#installation">Installation & Setup</a></li>
    <li><a href="#apache">Running through Apache</a></li>
    <li><a href="#security">Security Considerations</a></li>
    <li><a href="#service">Service Management</a></li>
    <li><a href="#updates">Application Management & Updates</a></li>
    <li><a href="#backup">Backup and Recovery</a></li>
    <li><a href="#configuration">Configuration options</a></li>
</ul>
</nav>
<main>
<section id="overview">
    <h2>Overview of uniGUI HyperServer</h2>
    <ul>
        <li><strong>HyperServer:</strong> The main entry point for all requests. It directs requests to appropriate Nodes (worker processes).</li>
        <li><strong>Nodes:</strong> Instances of your uniGUI application running in standalone EXE mode.</li>
        <li><strong>Persistent Nodes:</strong> Nodes that run continuously and can perform background tasks.</li>
        <li><strong>Transport:</strong> Handles internal communication between HyperServer and Nodes.</li>
    </ul>
</section>

<section id="installation">
    <h2>Installation and Setup on Linux</h2>

      <h3>1. Prepare Your Linux Environment</h3>
      <p>Before installing uniGUI HyperServer, ensure your Linux environment is prepared:</p>

      <h4>Update the System:</h4>
      <p>Run the following commands to update your package lists and upgrade installed packages.</p>
      <p><CodeBlock code={bashUpdateCommands} language="bash" /></p>

      <h4>Install Dependencies:</h4>
      <p>Install the necessary libraries that uniGUI HyperServer might depend on.</p>
      <p><CodeBlock code={bashDependenciesCommands} language="bash" /></p>

      <h3>2. Download and Install uniGUI HyperServer</h3>

      <h4>Download HyperServer:</h4>
      <p>Obtain the HyperServer executable from the official uniGUI website or the provider of the software.</p>

      <h4>Place HyperServer on Your Server:</h4>
      <p>Transfer the HyperServer executable and your uniGUI application binaries to the desired directory on your Linux machine. For example, place them in <code>/unigui/</code>.</p>
      <p><CodeBlock code={bashMoveCommands} language="bash" /></p>

      <h3>3. Configure HyperServer</h3>

      <h4>Create Configuration File:</h4>
      <p>HyperServer requires a configuration file (<code>hyper_server.cfg</code>). Create this file in the same directory as the HyperServer binary.</p>
      <p><CodeBlock code={bashConfigCommand} language="bash" /></p>

      <h4>Basic Configuration:</h4>
      <p>Here is an example of what your <code>hyper_server.cfg</code> file might look like:</p>
      <p><CodeBlock code={configFile} language="ini" /></p>

      <h3>Adjust Settings as Needed:</h3>
      <p>Modify the configuration file to match your specific application and deployment environment.</p>
   
</section>

<section id="apache">
    <h2>Running HyperServer through Apache</h2>
    <h3>Reverse Proxy Setup:</h3>
    <p>Configure Apache to reverse proxy to your HyperServer:</p>
    <p><CodeBlock code={code2} language='apache'></CodeBlock></p>
    <p>***Apache handles SSL, so SSL configuration in your uniGUI app is not needed if using Apache as a proxy.</p>
</section>

<section id="security">
    <h2>Security Considerations</h2>
    <h3>Using Apache for SSL:</h3>
    <p>If you are using Apache, let it handle SSL, which is a secure and standard practice.</p>
    <p>SSL configuration in the standalone application isn’t required unless you’re running the app directly without a reverse proxy.</p>
    <h3>Direct SSL Setup:</h3>
    <p>If running standalone or as a service without Apache, set up SSL using <code>&lt;server_name&gt;.ssl.cfg</code> for secure communication.</p>
</section>

<section id="service">
<h2>Setting Up HyperServer as a Service</h2>
            <h3>Create a Service File:</h3>
            <p>Navigate to the system's service directory:</p>
            <p><CodeBlock code={`sudo nano /etc/systemd/system/hyperserver.service`} language='bash' /></p>
            <p>Define the service in the file:</p>
            <p><CodeBlock code={codeServiceFile} language='ini' /></p>
            <h3>Enable and Start the Service:</h3>
            <p>Enable the service to start on boot:</p>
            <p><CodeBlock code={`sudo systemctl enable hyperserver.service`} language='bash' /></p>
            <p>Start the service:</p>
            <p><CodeBlock code={`sudo systemctl start hyperserver.service`} language='bash' /></p>
            <h3>Check Service Status:</h3>
            <p>To ensure that the service is running smoothly:</p>
            <p><CodeBlock code={`sudo systemctl status hyperserver.service`} language='bash' /></p>
</section>

<section id="updates">
<h2>Managing and Updating uniGUI Applications via HyperServer Browser Interface</h2>
    
    <h3>Accessing the HyperServer Management Interface</h3>
    
    <h4>Open Browser Interface:</h4>
    <p>Access the HyperServer management interface using a web browser. Navigate to a specific URL where the HyperServer is hosted. For example, <CodeBlock code={`http://your-ip:port/server`} language='text' /></p>
    
    <h4>Login:</h4>
    <p>If <CodeBlock code={`prompt_login=1`} language='ini' /> is set in the configuration, you will need to log in with the appropriate credentials. Ensure that the login details are secure and managed properly.</p>

    <h3>Managing Applications</h3>
    
    <h4>View Installed Applications:</h4>
    <p>Once logged in, you should see a dashboard or application management section. This section lists all the installed uniGUI applications managed by HyperServer.</p>
    
    <h4>Start/Stop Applications:</h4>
    <p>You can start or stop applications from the management interface. This is useful for controlling application availability or performing maintenance tasks.</p>
    
    <h4>View Application Logs:</h4>
    <p>Check application logs directly from the interface to monitor performance, errors, and other critical information.</p>
    
    <h4>Configure Application Settings:</h4>
    <p>Some settings related to applications can be configured from the browser interface. This may include adjusting performance parameters, setting up application-specific configurations, or managing user permissions.</p>

    <h3>Updating Applications</h3>
    
    <h4>Upload New Versions:</h4>
    <p>To update an application, upload the new version of the application binary or files. There should be an option to upload or replace existing application files from the management interface.</p>
    
    <h4>Deploy New Version:</h4>
    <p>After uploading, deploy the new version of the application. This may involve restarting the application or HyperServer to apply the updates.</p>
</section>

<section id="backup">
          <h2>Backup and Recovery</h2>
          <h3>Automatic Backup Creation:</h3>
          <ul>
            <li>When you deploy a new version of your application, HyperServer will automatically create a backup of the existing application binary.</li>
            <li>The backup is typically stored in the same directory as the original binary, with a .bak extension added to the file name.</li>
            <li>Backup Example: If your original application binary is named <code>myapp</code>, upon updating, HyperServer will create a backup named <code>myapp.bak</code>.</li>
            <li>This backup allows you to quickly revert to the previous version if something goes wrong with the update.</li>
          </ul>
          <h3>Restoring from Backup:</h3>
          <ul>
            <li>If you need to roll back to the previous version, you can replace the updated binary with the .bak file.</li>
            <li>Simply rename <code>myapp.bak</code> back to <code>myapp</code>, and restart the application or node.</li>
          </ul>
          <h3>Best Practices</h3>
          <ul>
            <li><strong>Verify Backups:</strong> Regularly check that backups are being created correctly and that they are usable for rollback.</li>
            <li><strong>Keep Multiple Versions:</strong> Consider keeping multiple backup versions if you update frequently, to ensure you have a stable fallback option.</li>
          </ul>
        </section>

        <section id="configuration">
    <h2>Configuration Options</h2>

    <h3>Critical Configuration Options</h3>

    <h4>Binary Name</h4>
    <p><CodeBlock code={`binary_name=myapp_binary`} language='ini' /></p>
    <p><strong>Importance:</strong> This specifies the main uniGUI application binary. It’s essential for HyperServer to know which application it is managing.</p>

    <h3>Framework Files Root</h3>
    <p><CodeBlock code={`framework_files_root=/etc/fmsoft/unigui/unigui_runtime`} language='ini' /></p>
    <p><strong>Importance:</strong> Specifies the path to the framework files used by uniGUI. Essential for the runtime environment.</p>

    <h4>Initial Nodes</h4>
    <p><CodeBlock code={`initial_nodes=2`} language='ini' /></p>
    <p><strong>Importance:</strong> Sets the number of nodes (processes) to start with. This helps in balancing the load from the beginning.</p>

    <h4>Maximum Nodes</h4>
    <p><CodeBlock code={`max_nodes=8`} language='ini' /></p>
    <p><strong>Description:</strong> Defines the upper limit of nodes that can be spawned. Important for scaling your application according to traffic needs.</p>

    <h4>Maximum Connections</h4>
    <p><CodeBlock code={`max_connections=500`} language='ini' /></p>
    <p><strong>Description:</strong> Limits the number of simultaneous connections the server can handle, preventing overload.</p>

    <h3>Prompt Login</h3>
    <p><CodeBlock code={`prompt_login=1`} language='ini' /></p>
    <p><strong>Importance:</strong> Requires users to log in before accessing the hyperserver, which can enhance security.</p>

    <h4>Allow Remote Configuration</h4>
    <p><CodeBlock code={`allow_remote_config=1`} language='ini' /></p>
    <p><strong>Importance:</strong> Enables remote configuration of HyperServer. Useful for managing the server remotely but should be secured properly.</p>

    <h4>Disable Automatic Backup:</h4>
    <p><CodeBlock code={codeDisableBackup} language='ini' /></p>
    <p><strong>Importance:</strong> If you do not want HyperServer to create backups automatically, you can disable this feature in the configuration file</p>

    <h4>Token</h4>
    <p><CodeBlock code={`token=**************`} language='ini' /></p>
    <p><strong>Importance:</strong> A security token used to authenticate requests. It’s crucial for securing server communications.</p>

    <h4>Enable WebSockets</h4>
    <p><CodeBlock code={`enabled=1`} language='ini' /></p>
    <p><strong>Importance:</strong> Activates WebSocket support, which is essential for real-time communication if your application requires it.</p>

    <h4>Enable Node Recycling</h4>
    <p><CodeBlock code={`enabled=1`} language='ini' /></p>
    <p><strong>Importance:</strong> Enables recycling of nodes to manage resource usage and improve performance.</p>

    <h3>Performance and Maintenance</h3>

    <h4>Max Requests</h4>
    <p><CodeBlock code={`max_requests=500`} language='ini' /></p>
    <p><strong>Description:</strong> Sets the limit on the number of simultaneous requests a node can handle, which helps manage server load.</p>

    <h4>HTTP Max Pool</h4>
    <p><CodeBlock code={`http_max_pool=500`} language='ini' /></p>
    <p><strong>Description:</strong> Configures the maximum number of HTTP connections in the pool, affecting connection handling performance.</p>

    <h4>Recycle After Seconds</h4>
    <p><CodeBlock code={`recycle_after_secs=3600`} language='ini' /></p>
    <p><strong>Description:</strong> Specifies the interval after which nodes will be recycled, helping to free up resources and maintain server health.</p>

    <h3>Optional but Useful</h3>

    <h4>Detailed Log</h4>
    <p><CodeBlock code={`detailed_log=0`} language='ini' /></p>
    <p><strong>Description:</strong> Enabling detailed logging (`1`) can be helpful for troubleshooting but may generate large log files.</p>

    <h4>Anti-flood Per IP</h4>
    <p><CodeBlock code={`antiflood_per_ip=0`} language='ini' /></p>
    <p><strong>Description:</strong> Prevents any single IP from making too many requests in a short period, which can protect against abuse.</p>

    <h4>Session One Per IP</h4>
    <p><CodeBlock code={`session_one_per_ip=0`} language='ini' /></p>
    <p><strong>Description:</strong> Restricting to one session per IP can help in scenarios where session control is critical.</p>

    <h3>Optional</h3>

    <h4>External Server URL</h4>
    <p><CodeBlock code={`external_server_url=`} language='ini' /></p>
    <p><strong>Description:</strong> Used if integrating with external servers. Not needed if you're only using local resources.</p>

    <h4>Custom Error Pages</h4>
    <p><CodeBlock code={`error_page_404=/path/to/404.html\nerror_page_500=/path/to/500.html`} language='ini' /></p>
    <p><strong>Description:</strong> Configures custom error pages for better user experience and branding.</p>

    <h4>Server Title</h4>
    <p><CodeBlock code={`server_title=`} language='ini' /></p>
    <p><strong>Description:</strong> Provides a title for the server, useful for identification, especially in environments with multiple servers.</p>

    <h4>Server Build</h4>
    <p><CodeBlock code={`server_build=1.95.0.1583`} language='ini' /></p>
    <p><strong>Description:</strong> Indicates the build version of the server, helpful for tracking versions and updates.</p>
</section>
          
          
</main>
</SheetContainer>
<Footer />
</>;
};
  
  export default HyperServer;