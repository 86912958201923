import React from "react";
import styled from 'styled-components';
import Footer from './../components/Footer'

const TermsSection=styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    min-height: 100vh;
`

const SheetContainer=styled.section`
    width: 1000px;
    border-top: 4px solid rgb(0,68,102);
    text-align: center;
    min-height: 600px;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 20px;
    background-color: white;
`


const Terms = () => {
    return <TermsSection>
    <SheetContainer>
    <h1>Terms and Conditions</h1>
<p>Last updated: November 18, 2022</p>
<p>Please read these terms and conditions carefully before using Our Site.</p>
<h1>Acknowledgment</h1>
<p>These are the Terms and Conditions governing the use of this Website and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Website.</p>
<p>Your access to and use of the Website is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>

<h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
<p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and Yellow Ray Consulting assumes no responsibility or liability for any errors or omissions in the content of this site. The information on this site is provided with no guarantees of completeness, accuracy or usefulness and without any warranties of any kind.</p>
<h1>Links to Other Websites</h1>
<p>Our Website may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
<p>Yellow Ray Consulting has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
<p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
<h1>Changes to These Terms and Conditions</h1>
<p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.</p>
<p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
<h1>Contact Us</h1>
<p>If you have any questions about these Terms and Conditions, You can contact us:</p>
<ul>
<li>By email: info@yrcgi.com</li>
</ul>
</SheetContainer>
    < Footer />
    </TermsSection>
  };
  
  export default Terms;
