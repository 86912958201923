import "./App.css";
import SideBar from "./components/Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Home.jsx";
import GitAbout from "./Pages/Git/About.jsx";
import GitDelphi from "./Pages/Git/Delphi.jsx";
import GitGHD from "./Pages/Git/GitHubDesktop";
import Postgres from "./Pages/Postgres/Setup";
import HowTo from "./Pages/Postgres/HowTo";
import DelphiInstall from "./Pages/Delphi/Delphi";
import TMSWEBcore from "./Pages/Delphi/TMSSoftware/TMSWEBcore";
import TMSBiz from "./Pages/Delphi/TMSSoftware/TMSBiz";
import TMSXdata from "./Pages/Delphi/TMSSoftware/XData";
import UniGuiInstall from "./Pages/Delphi/uniGUI/GUIInstall";
import ApacheSetup from "./Pages/Delphi/uniGUI/ApacheSetup";
import InitialProject from "./Pages/Delphi/uniGUI/InitialProject";
import UniSSL from "./Pages/Delphi/uniGUI/uniGUISSL";
import HyperServer from "./Pages/Delphi/uniGUI/HyperServer";
import FastReports from "./Pages/Delphi/FastReports";
import DelphiHow from "./Pages/Delphi/DelphiHow";
import WebcoreHow from "./Pages/Webcore/How";
import WebcoreAbout from "./Pages/Webcore/About";
import VSCReact from "./Pages/WebDevelopment/ReactApps";
import VSC from "./Pages/WebDevelopment/VSC";
import TMS from "./Pages/WebDevelopment/TMS";
import LinuxCommands from "./Pages/Linux/LinuxCommands";
import LinuxUbuntu from "./Pages/Linux/LinuxUbuntu";
import ServerConfig from "./Pages/Linux/ServerConfig";
import DataMigration from "./Pages/Linux/DataMigration";
import Users from "./Pages/Linux/SSUsers";
import MountedDrives from "./Pages/Linux/MountingDrives";
import TimeChange from "./Pages/Linux/TimeChange";
import ServerUpgrade from "./Pages/Linux/ServerUpgrade";
import Backups from "./Pages/Linux/Backups";
import CronJobs from "./Pages/Linux/CronJobs";
import Rsync from "./Pages/Linux/Rsync";
import WSL from "./Pages/Windows/WSL";
import WSLDev from "./Pages/Windows/Wsl-Dev";
import Info from "./Pages/Info";
import Terms from "./Pages/Terms";

function App() {
  return (
    <Router>
      <SideBar>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/git/about" element={<GitAbout />} />
          <Route path="/git/delphi" element={<GitDelphi />} />
          <Route path="/git/ghd" element={<GitGHD />} />
          <Route path="/postgres/setup" element={<Postgres />} />
          <Route path="/postgres/howto" element={<HowTo />} />
          <Route path="/delphi/delphi-install" element={<DelphiInstall />} />
          <Route path="/delphi/tms/webcore" element={<TMSWEBcore />} />
          <Route path="/delphi/tms/biz" element={<TMSBiz />} />
          <Route path="/delphi/tms/xdata" element={<TMSXdata />} />
          <Route path="/delphi/unigui/install" element={<UniGuiInstall />} />
          <Route path="/delphi/unigui/apachesetup" element={<ApacheSetup />} />
          <Route path="/delphi/unigui/project" element={<InitialProject />} />
          <Route path="/delphi/unigui/ssl" element={<UniSSL />} />
          <Route path="/delphi/unigui/hyperserver" element={<HyperServer />} />
          <Route path="/delphi/fast-reports" element={<FastReports />} />
          <Route path="/delphi/delphi-how" element={<DelphiHow />} />
          <Route path="/web/react-apps" element={<VSCReact />} />
          <Route path="/web/vsc" element={<VSC />} />
          <Route path="/web/tms" element={<TMS />} />
          <Route path="/webcore/about" element={<WebcoreAbout />} />
          <Route path="/webcore/how" element={<WebcoreHow />} />
          <Route path="/linux/linux-commands" element={<LinuxCommands />} />
          <Route path="/linux/linuxubuntu" element={<LinuxUbuntu />} />
          <Route path="/linux/serverconfig" element={<ServerConfig />} />
          <Route path="/linux/datamigrate" element={<DataMigration />} />
          <Route path="/linux/users" element={<Users />} />
          <Route path="/linux/mounted-drives" element={<MountedDrives />} />
          <Route path="/linux/time-change" element={<TimeChange />} />
          <Route path="/linux/server-upgrade" element={<ServerUpgrade />} />
          <Route path="/linux/backups" element={<Backups />} />
          <Route path="/linux/cron-jobs" element={<CronJobs />} />
          <Route path="/linux/rsync" element={<Rsync />} />
          <Route path="/windows/wsl" element={<WSL />} />
          <Route path="/windows/wsl-dev" element={<WSLDev />} />
          <Route path="/info" element={<Info />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="*" element={<> not found</>} />
        </Routes>
      </SideBar>
    </Router>
  );
}

export default App;
