import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
img {
  
}
`





const XData = () => {
    return <>
          <SheetContainer>
            <h1>TMS XData</h1>
            <ul>
              <li>TMS XData is designed to simplify the creation of RESTful services in Delphi, allowing developers to build scalable and efficient web services.</li>
              <li>XData supports Object-Relational Mapping, allowing you to map Delphi classes to database tables. This makes it easier to work with databases and eliminates the need for writing complex SQL queries.</li>
              <li>Provides components and features for handling data access, including the ability to perform CRUD (Create, Read, Update, Delete) operations on database records through RESTful endpoints.</li>
              <li>XData includes features for implementing authentication and authorization in your RESTful services. This is important for securing access to sensitive data and operations.</li>
              <li>XData helps the development of multi-tier applications by enabling communication between client applications and server-side components through RESTful services.</li>
              <li>Easy integration with other TMS tools within Delphi.</li>
              <li>There is a fair amount of documentation and tutorials/courses to help Developers learn TMS XData. <a href="https://doc.tmssoftware.com/biz/xdata/guide/index.html" target="Blank">TMS XData Documentation</a></li>
            </ul>

            <h2>Singleton Pattern</h2>
            <ol>
              <li></li>
            </ol>

            <h2>Access Control</h2>
            <ol>
              <li>Authentication: Who is accessing the service</li>
              <li>Authorization: Is a user allowed to access a certain resource</li>
              <li>Credentials: Usually a username and password</li>
              <li>JWT: JSon Web Token</li>
            </ol>
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default XData;