import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import CodeBlock from '../../components/Codeblock';


const SheetContainer=styled.section`
    width: 100%;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    h1 {
        font-size: 60px;
        text-align: center;
        margin-bottom: 40px
    }
    ol, li {
        list-style-type: number;
        font-size: 20px;
        margin: 20px;
        line-height: 2;
    }
    mark {
        background-color: black;
        border-radius: 6px;
        color: white;
        padding: 7px;
        font-style: italic;
      }
`




const ServerBackups = () => {
    const code1 =`cd path/to/folder`
    return <>
    <SheetContainer>
       <h1>Manual PostgreSQL Backups</h1>
       <h2>We have two styles of backups:</h2>
       <h4>pg_dumpall - dumps all data from all databases</h4>
       <h4>pg_dump - dumps all data for a specified database. Data is dumped into a folder name which matches database name</h4>
       <ol>
        <li>Login using putty <br />**Do not login as root. You have to be a user known to PG </li>
		<li>Move to first folder: <CodeBlock code={code1}></CodeBlock></li>
	    <li>Run backup script <mark>bash 'bash script text file'</mark> </li>
		<li>Move to second folder <mark>cd other_folder\...</mark></li>
        <li>Run backup script <mark>bash 'bash script text file'</mark></li>
        <li>Repeat these two steps for the rest of folders you wish to backup</li>
		<li>Finally start FileZilla and download backups to your workstation</li>
    </ol>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default ServerBackups;