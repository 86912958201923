import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,68,102);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
  line-height: 2;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
`





const AboutGit = () => {
  const code1 =`git --version`
    return <>
          <SheetContainer>
            <h1>About Git</h1>
            <ul>
              <li>Git dominates open source and professional development ecosystem</li>
              <li>Git is built into Delphi and RAD Studio as well as Visual Studio Code</li>
              <li>Git tracks all the changes made</li>
              <li>Stores those changes as a viewable history, with comments</li>
              <li>Handles changes from multiple developers</li>
              <li>When used with a cloud provider - saves your code safely off-site: <br />
		                GitHub - owned by Microsoft, what YR uses. <br />
		                GitLab - Privately owned, fully remote company.</li>
              <li>Allows you to create branches for bug fixes or new features you are working on</li>
              <li>Can merge changes from multiple developers and even your own branches into the main source tree</li>
              <li>Allows you to easily revert bad changes or experiments which didn't work out</li>
              <li>Allows you to collaborate with major open source projects</li>
              <li>Can pinpoint who made a change and therefore who to blame</li>
              <li>Allows others to make changes to shared source code in a very managed way</li>
              <li>Allows you to share your source code on public web-based services like GitHub, GitLab, and BitBucket</li>
            </ul>
            <h1>Installing Git on your machine</h1>
            <ol>
              <li>Git for Windows: <a href="https://gitforwindows.org" target="_blank" rel="noreferrer">Link to download site</a></li>
              <li>Accept all the Git for Windows defaults</li>
              <li>Make a note of where it installs because you will need it later. The default for 64 bit Windows is: c:\Program Files\Git\bin\git.exe</li>
              <li>Once installed on your machine go to the terminal and type command: <CodeBlock code={code1}></CodeBlock> <br />
			        Most current Git version for Windows- 2.42.0.2 <br />
	            This has to work before setup in Delphi IDE</li>

            </ol>

          </SheetContainer>
          <Footer />
          </>
  };
  
  export default AboutGit;