import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'


const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
li {
  margin: 20px;
  font-size: 20px;
  list-style-type: numbers;
}
mark {
  background-color: black;
  color: white;
  padding: 8px;
  font-style: italic;
}
`





const LearnVSC = () => {
    return <>
          <SheetContainer>
            <h1>Visual Studio Code Install</h1>
              <ol>
                <li>Download the installer for windows <a href="https://code.visualstudio.com/download">here.</a></li>
                <li>Once downloaded run installer as administrator.</li>
                <li>You can also download a Zip folder and extract it in desired place.</li>
                <li>Updates can be set to automatically happen when updates are available or you will be prompted within VSCode to update.</li>
              </ol>
          </SheetContainer>
          <Footer />
    </>
  };
  
  export default LearnVSC;