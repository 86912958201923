import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,68,102);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1, h3 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: numbers;
  line-height: 2;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
p {
    font-size: 20px;
}
a {
  font-size: 20px;
}
`




const ServerUpgrade = () => {
    const restore = `pg_restore -U username -d dbname backup.dump`;
    const mkdir = `mkdir pg-data`;
    const scp = `scp username@remote_host:/path/to/source/file local_destination`;
    const psql = `psql dbname --file=dump_file.sql`;
    return <>
    <SheetContainer>
       <h1>Database Migration</h1>
       <p>The process of copying a database from one server to another is commonly referred to as database migration. This involves transferring the entire database, including its schema and data, from one server to another. There is different ways of migrating databases, listed below is just a couple of them.</p>
       <ul>
       <li>Backup and Restore: You can create a backup of the database on the source server and then restore it on the destination server. For PostgreSQL Use command: <CodeBlock code={restore}></CodeBlock></li>
        <li>Database Dump: Creating a dump of the database, which is a file containing SQL statements to recreate the database structure and insert the data: 
            <ol>
                <li>Log in with your username</li>
                <li>Make a new directory to store the dump files that have been created from the databases you wish to migrate: <CodeBlock code={mkdir}></CodeBlock></li>
                <li>Secure copy the dump file from the source server : <CodeBlock code={scp}></CodeBlock></li>
                <li>After password is entered for the user of the source server, the secure copy will start and will display how long it will take.</li>
                <li>Make sure permissions are set so you can run psql statements, also either a .pgpass is in place or your pg users password is handy.</li>
                <li>Navigate to the directory you copied the dump file and use command: <CodeBlock code={psql}></CodeBlock></li>
                <li>You should then see it run and then navigate to psql command line and make sure the database was created!</li>
            </ol>
        </li>
       </ul>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default ServerUpgrade;