import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import {languageSVG} from '../../assets';
import {alreadySVG} from '../../assets';
import {agreementSVG} from '../../assets';
import {licenseSVG} from '../../assets';
import {themeSVG} from '../../assets';
import {platformsSVG} from '../../assets';
import {additionalSVG} from '../../assets';


const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
border-top: 4px solid red;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
h4 {
  margin: 20px;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: numbers;
}
img {
  width: 300px;
  margin: 30px;
  align-self: center;
}
`



const Delphi = () => {
    return <>
    <SheetContainer>
       <h1>Delphi Install Notes</h1>
    <h2>Installation-Delphi Enterprise 10.4.2</h2>
    
    <ol>
    
    <li><h4>Open File:</h4>radstudio_10_4_esd_104203a.exe -Run as administrator</li>
    <li>Language-English(or Language of your choice)</li>
    <img src= { languageSVG } alt="Language"></img>
    <li>I agree to the RAD Studio License Agreement and Privacy Policy-Check</li>
    <img src= { agreementSVG } alt="Agreement"></img>
    <li>Improve RAD Studio (optional)-Leave checked or un-check,then click Next</li>
    <li>Choose from options shown below- I already have a product serial number-Check</li>
    <img src= { alreadySVG } alt="AlreadyHaveLicense?"></img>
    <li>Embarcadero Product Registration-</li>
    <img src= { licenseSVG } alt="RadLicense"></img>
    <li>RAD Studio Platform Selection, choose needed platforms then click Continue.</li>
    <img src= { platformsSVG } alt="RadPlatforms"></img>
    <li>RAD Studio Additional Options, Selected list below-
        <ol>
        <li>Samples</li>
        <li>TeeChart Standard</li>
        <li>DUnit Unit Testing Frameworks</li>
        </ol>
        <img src= { additionalSVG } alt="AdditionalOptions"></img>
    </li>
    <li>Synopsis- Download Size, Download Time and Required Space listed beside Install button</li>
    <li>Click Install</li>
    <li>Windows Software Development Kit, save in default location or location of choice</li>
    <li>When install is complete, Click Start Working</li>
    <li>Delphi Opens</li>
    <li>Choose a Theme</li>
    <img src= { themeSVG } alt="ChooseTheme"></img>
    <li>Configure Source Control</li>
    <li>Personalize Settings</li>
    </ol>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default Delphi;