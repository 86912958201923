import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';

const SheetContainer=styled.section`
    width: 90%;
    border-top: 4px solid yellow;
    text-align: left;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 30px;
    background-color: back;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
      line-height: 40px;
    }
    h2 {
      text-align: center;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`

const Users = () => {
  return <>
     <SheetContainer>
              <h2>Users</h2>
              <h3>Adding Linux Users</h3>
              <ol>
                <li>Make sure you are logged in as a sudo/root user</li>
                <li>Enter: <mark>adduser user123</mark></li>
                <li>Next you will be prompted for the new users password.</li>
                <li>Once new password is entered it will prompt to re-enter password to verify.</li>
                <li>It will then prompt for any more info you'd like to attach to this user- Example: Full Name</li>
                <li>If you do not wish to add any additional information, click through prompts</li>
                <li>Lastly logout and try logging back in with new user and password</li>
                </ol>
                <h3>Adding Users without a home directory</h3>
                <ol>
                  <li>Again make sure you are logged in as root user.</li>
                  <li>use command: <mark>adduser --force-badname --no-create-home newusername</mark></li>
                  <li>You will then be prompted for password and user info.</li>
                  </ol>
                <h2>Removing a User</h2>
                <ol>
                  <li>Command is <mark>userdel</mark> Example: <mark>userdel user123 -r</mark></li>
                  <li><mark>-r</mark> removes user home directory</li>
                </ol>
                <h3>Changing Users to be SuperUsers</h3>
                <ol>
                  <li>log in as root user</li>
                  <li>use command: <mark>usermod -aG sudo username</mark></li>
                  <li>Check to see if it updated using command: <mark>groups username</mark></li>
                </ol>
                <h3>Changing Passwords</h3>
                <ol>
                  <li>Log in as user you wish to change</li>
                  <li>enter command: <mark>passwd</mark></li>
                  <li>you will then be prompted to:<br/>-Enter original pw <br/>-Enter new pw <br/>-Confirm new pw</li>
                </ol>
            </SheetContainer>

        <SheetContainer>
              <h2>Managing and Creating Groups</h2>
              <ol><li>Move to /etc/group file, this is where group info is held.</li>
                <li>To list users who already exist in a group use command:  <mark>getent group group-name</mark> </li>
                <li>To create a group, type the following in command line:  <mark>groupadd -g group-ID group-name</mark> </li>
                <li>To add an existing user to another group, type: <mark>usermod -G groupa,groupb user-name</mark></li>
                <li>To remove a user from a group, list the groups you want user to remain in: <mark>usermod -G groupa,groupb user-name</mark>  or  <mark>usermod -G groupb user-name</mark> </li>
              </ol>
        </SheetContainer>
            <SheetContainer>
              <h2>Setting up SSH keys</h2>
              <ul>
                <li>Login with password</li>
                <li>Enter command <mark>mkdir .ssh</mark></li>
                <li>Then <mark>chmod 700 .ssh</mark></li>
                <li>Move into the directory: <mark>cd .ssh</mark></li>
                <li>Enter command: <mark>vi authorized_keys</mark></li>
                <li>Once you are in the text editor copy and paste public key, save :w then exit :q</li>
                <li>Then <mark>chmod 600 authorized_keys</mark></li>
                <li>Command: <mark>ls -al</mark> to make sure key file was created and permissions were changed</li>
                <li>Logout and check to see if you can log back in without password</li>
              </ul>
    </SheetContainer>
    <Footer/>
    </>
  };

  
  export default Users;