import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
li {
  margin: 20px;
  font-size: 20px;
  list-style-type: numbers;
  line-height: 2;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
Codeblock {
  width: auto;
}
`

const ReactApp = () => {
  const coderun =`npm run build`;
  return <>
  <SheetContainer>
  <h1>Getting started with React-App in VSCode</h1>
<ul>
<li>First you must have Node.js installed and npm as well, npm is included in the <a target="blank" href="https://nodejs.org/en/download">Node.js download.</a></li>
<li>To check if you have Node.js and npm installed and up to date you can enter <mark>node --version</mark> and <mark>npm --version</mark> in the terminal.</li>
<li>You are now able to create a React App, in the terminal enter <mark>npx create-react-app app-name</mark> , app-name would be the folder you want your application in.</li>
<li>Create-react-app automatically creates a sample app so you will have to delete some code prior to starting your application.</li>
<li>To see application in a browser- first <mark>cd app-name</mark> then enter <mark>npm start</mark> this will start the development server on http://localhost:3000.</li>
<li>ctrl+c in the terminal to stop app running on system.</li>
<li>There is alot of documentation and links available within the README file created with npx create-react-app.</li>
</ul>

<h1>Changing the title of App</h1>
<ol>
<li>Go to the index.html file in VSCode</li>
<li>Find the title tags and change the default title(React App) to your desired title.</li>
<li>If your App is running, simply refresh your browser and you will see the title on the tab change.</li>
</ol>
<h1>Favicon for React App</h1>
<ol>
<li>A favicon is a small icon related to your website(A visual identifier), displayed on a browser tab, beside the title of your site.</li>
<li>Within VSCode in the public folder, find the favicon.ico file. Right click and delete this file.</li>
<li>Find a new favicon preferably a .png format or you can generate your own using a favicon generator online.</li>
<li>Drag and drop the new image into your public file and rename file to favicon.ico</li>
<li>Save, and if your apllication is running refresh to see your new favicon.</li>
<li>If your new favicon is not showing up make sure you have it in the correct folder and check the spelling. Your favicon should be accessible if you enter website.name/favicon.ico</li>
</ol>
<h1>Deploying a React Application</h1>
<ul>
<li>Using VSCode, Filezilla FTP</li>
<li>To create a production build you will need to run <CodeBlock code={coderun}></CodeBlock></li>
<li>Once Run build is completed, a new folder called build will be created, copy ALL the files in the build folder and transfer them using Filezilla to your website server.</li>
<li>If you are updating the site and already have files on your web server, simply delete all old files and then transfer new build files over.</li>
</ul>
  </SheetContainer>
  < Footer />
  </>
};

export default ReactApp;