import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'
import {setup1SVG} from '../../../assets';
import {setup2SVG} from '../../../assets';
import {setup3SVG} from '../../../assets';
import {setup4SVG} from '../../../assets';
import {setup5SVG} from '../../../assets';
import {setup6SVG} from '../../../assets';
import {setup7SVG} from '../../../assets';
// import {setup8SVG} from '../../assets';
import {setup9SVG} from '../../../assets';
import {setup10SVG} from '../../../assets';
import {setup11SVG} from '../../../assets';
import {setup12SVG} from '../../../assets';
import {setup13SVG} from '../../../assets';
import {setup14SVG} from '../../../assets';

const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid red;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: numbers;
}
img {
  width: 300px;
  margin: 30px;
  align-self: center;
}
`



const TMSDelphi = () => {
    return <>
    <SheetContainer>
       <h1>TMS Webcore Install for Delphi</h1>
       <ol>
       <li>Welcome page, click Next</li>
       <img src= { setup1SVG } alt="Welcome"></img>
       <li>Accept the License Agreement, then click Next</li>
       <img src= { setup2SVG } alt="License Agreement"></img>
       <li>Choose where TMS Web Core Wrapper Installer for Delphi should be installed, then click Next</li>
       <img src= { setup3SVG } alt="Destination Location"></img>
       <li>Select Start Menu Folder, then click Next</li>
       <img src= { setup4SVG } alt="Start Menu"></img>
       <li>Ready to install, click Install</li>
       <img src= { setup5SVG } alt="Ready"></img>
       <li>Success, click Finish</li>
       <img src= { setup6SVG } alt="Finish"></img>
       <li>License Agreement, accept and click Next</li>
       <img src= { setup7SVG } alt="License Agreement"></img>
       <li>Select start menu folder, then next.</li>
       <li>Choose Version, then Next</li>
       <img src= { setup9SVG } alt="Folder"></img>
       <li>Select start menu folder for specific version</li>
       <img src= { setup10SVG } alt="Folder"></img>
       <li>Click Install, this will install into Delphi version chosen.</li>
       <img src= { setup11SVG } alt="Folder"></img>
       <li>More Information, Next</li>
       <img src= { setup12SVG } alt="Folder"></img>
       <li>Completing Install into Delphi Version, Finish.</li>
       <img src= { setup13SVG } alt="Folder"></img>
       <li>Within Delphi you will now have the TMS Web Core options when you click New Menu. </li>
       <img src= { setup14SVG } alt="tmsOptions"></img>
       </ol>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default TMSDelphi;