import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};

const SidebarMenu = ({ route, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  const toggleSubMenu = (subRouteIndex) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [subRouteIndex]: !prevState[subRouteIndex],
    }));
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
      setOpenSubMenus({});
    }
  }, [isOpen]);

  return (
    <>
      <div className={"menu"} onClick={toggleMenu}>
        <div className="menu_item">
          <div className="icon">{route.icon}</div>
          <AnimatePresence>
            {isOpen && (
              <motion.div className="link_text">
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
          {route.subRoutes && (
            <motion.div animate={isMenuOpen ? { rotate: -180 } : { rotate: 0 }}>
              <FaAngleDown />
            </motion.div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuAnimation}
            initial="show"
            animate="show"
            exit="show"
            className={"menu_container"}
          >
            {route.subRoutes.map((subRoute, i) => (
              <motion.div key={i} custom={i}>
                <NavLink to={subRoute.path} className="link">
                  <div className="icon">{subRoute.icon}</div>
                  <motion.div className="link_text">{subRoute.name}</motion.div>
                  {subRoute.subMenus && (
                    <motion.div
                      className="submenu_icon"
                      onClick={() => toggleSubMenu(i)} // Use index to identify the submenu
                      animate={openSubMenus[i] ? { rotate: -180 } : { rotate: 0 }} // Adjust based on openSubMenus state
                    >
                      <FaAngleDown />
                    </motion.div>
                  )}
                </NavLink>
                <AnimatePresence>
                  {openSubMenus[i] && subRoute.subMenus && (
                    <motion.div
                      variants={menuAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className={"submenu_container"}
                    >
                      {subRoute.subMenus.map((nestedSubRoute, j) => (
                        <motion.div key={j} custom={j}>
                          <NavLink to={nestedSubRoute.path} className="link2">
                            <div className="icon">{nestedSubRoute.icon}</div>
                            <motion.div className="link_text2">
                              {nestedSubRoute.name}
                            </motion.div>
                          </NavLink>
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SidebarMenu;