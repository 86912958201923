import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';


const SheetContainer=styled.section`
    width: 98%;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    h1 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 40px
    }
    ol, li {
        list-style-type: number;
        font-size: 20px;
        padding: 10px;
        margin: 20px;
        line-height: 2.5;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`

const Time = () => {
  return <>
  <SheetContainer>
     <h1>How to Set Local Timezone</h1>
     <ol>
      <li>Log in as root user</li>
      <li>To display the current time and date on your system, use- <mark>timedatectl</mark></li>
      <li>Another way to view the time zone- <mark>ls -l /etc/localtime</mark></li>
      <li>To list all time zones enter command- <mark>timedatectl list-timezones</mark></li>
      <li>To set your local timezone in Linux, run following command as a root or sudo user-<mark>timedatectl set-timezone Canada/Saskatchewan</mark> </li>
      <li>Run <mark>timedatectl</mark> again to verify.</li>
      <li>After a Reboot make sure timezone remains correct.</li>
      </ol>
      <h1>How to set time and date</h1>
      <ol>
      <li>To set the time only, use- <mark>timedatectl set-time 13:45:30</mark></li>
      <li>If you get an error you may need to disable the NTP synchronizing</li>
      <li>Use this command- <mark>timedatectl set-ntp false</mark></li>
      <li>To set Time and Date enter- <mark>timedatectl set-time '2023-04-01 15:12:45'</mark></li>
      <li>After a reboot check to make sure time and date is correct</li>
     </ol>
  </SheetContainer>
  < Footer />
  </>
};

  
  export default Time;