import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,68,102);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1, h3 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
  line-height: 2;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
a {
  font-size: 20px;
}
`





const GitDelphi = () => {
  const code1 = `git clone`;
  const codeA = `git init`;
  const codeB = `git add --all`;
  const codeC = `git commit -m "Push existing project to GitHub"`;
  const codeD = `git remote add origin https://github.com/user/example_website.git`;
  const codeE = `git push -u -f origin main`;
    return <>
          <SheetContainer>
            <h1>Git with Delphi</h1>
            <a href="https://github.com" target="_blank" rel="noreferrer">GitHub Home Page</a>
            <ol>
                <li>Telling RAD Studio your Git settings: <br />
	              In RAD Studio IDE select Tools | Options from the menu <br />
	              From menu select Version Control | Git</li>
                  <li>Remember the path I told you to make a note of when you install Git for Windows, this is where you will need it.</li>
                  <li>First commit will require authorization to store source data, Once pw used then it will be cached</li>
                  <li>The user name and email are the ones you use with your Git hosting company and/or typed in during the Git for Windows installation</li>
                  <li>Using Git - Opening an existing repository</li>
            </ol>
            <h3>Cloning from GitHub</h3>
            <ul>
              <li>In file explorer, create the new or find the existing folder you would like to place the project in.</li>
              <li>Right click and push git bash here</li>
              <li>Navigate to the main page of the correct repository within your github account.</li>
              <li>Above the list of files you will find a button marked code, this will give you a drop down menu with options to clone.</li>
              <li>Clone with HTTPS, or another option if you prefer.</li>
              <li>Return to Git Bash type : <CodeBlock code={code1}></CodeBlock>then paste the URL you just copied.</li>
              <li>Press enter to create your local clone.</li>
            </ul>
            <ol>
              <h3>Commands to run in the existing project to push to GitHub-</h3>
              <li>First find project in file explorer, then right click within project folder and click git bash here</li>
              <li>Then run following commands:</li>
              <li><CodeBlock code={codeA}>git init</CodeBlock></li>
              <li><CodeBlock code={codeB}></CodeBlock></li>
              <li>Write a memo :<CodeBlock code={codeC}></CodeBlock></li>
              <li><CodeBlock code={codeD}></CodeBlock></li>
              <li><CodeBlock code={codeE}></CodeBlock></li>
              <li>Verify if the project was successfully pushed by logging into Github.</li>
            </ol>
          </SheetContainer>
          <Footer />
    </>
  };
  
  export default GitDelphi;