import styled from 'styled-components';
import React from 'react';
// import { contactSVG } from './../assets';
import { ronSVG } from './../assets';
import { vladSVG } from './../assets';
import { chrissySVG } from './../assets';
import Footer from '../components/Footer';


const AboutSection= styled.section`
display: flex;
flex-direction: column;
z-index: 100;
width: 100%;
`
const SheetContainer=styled.section`
    display: flex;
    border-top: 4px solid rgb(0,68,102);
    height: auto;
    max-width: 100%;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 20px;
    background-color: white;
    z-index: 100;
mission {
    display: flex;
    flex-direction: row; 
    align-self: flex-end;
    }
story {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}    
h1 {
  font-size: 40px;
  margin: 15px;
  text-decoration: underline;
}
p {
  text-align: center;
  font-size: 25px
}
`
const Title= styled.div`
background: rgb(0, 71, 187);
font-size: 70px;
display: flex;
justify-content: center;
align-content: center;
border-radius: 20px;
color: white;
width: 100%;
padding: 20px;
text-align: center;
`


const Team=styled.div`
  display: flex;
  flex-direction: row; 
  padding: 30px;
  margin: 50px;
  height: auto;
  width: 100%;
img {
  height: 300px;
  margin: 40px;
  border: 10px black;
  border-style: groove;
  border-radius: 20px;
}
h1 {
  font-size: 40px;
  align-self: center;
  margin: 20px;
}
text-decoration: underline;
`



const Info = () => {
    return <AboutSection>
      <Title> About YellowRay Consulting Group</Title>
      <Team><h1>Our Team</h1>
      <img src= { ronSVG } alt="contact"></img> <img src= { vladSVG } alt="contact"></img> <img src= { chrissySVG } alt="contact"></img></Team>
      {/* <Contact><img src= { contactSVG } alt="contact" /></Contact> */}
      <SheetContainer>
      <mission> 
      <h1>Our Mission</h1>
      <p>We provide service to design and develop database applications, perform data conversions, and create custom reports while also ensuring that the data meets all project requirements for data entry, warehousing and statistical or geographic analysis.</p>
      </mission>
      </SheetContainer>
      <SheetContainer>
        <story>
      <h1>Our Story</h1>
      <p>Yellow Ray Consulting Group Inc. has been in business since 1986, previously operating as Optima Research Inc. and Templeman Consulting.Yellow Ray's original business focus was statistical data analysis. Over the past 30 years, it became obvious clients needed as much or more help managing data. Data management, data governance, data stewardship and general information management has become a key part of our business.</p>
      </story>
      </SheetContainer>
      < Footer/>
      </AboutSection>
  };
  
  export default Info;