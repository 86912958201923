import { NavLink } from "react-router-dom";
import { FaHome, FaLinux, FaGitAlt } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { BsWindows } from "react-icons/bs";
// import { BiSearch } from "react-icons/bi";
import { SiPostgresql, SiDelphi } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";
import { useState } from "react";
import { motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import styled from 'styled-components';
import { logoSVG } from "./../assets";
const routes = [
  {
    path: "/",
    name: "Home",
    icon: <FaHome />,
  },
  {
    path: "/git",
    name: "Git",
    icon: <FaGitAlt />,
    subRoutes: [
      {
        path: "/git/about",
        name: "About Git",
      },
      {
        path: "/git/delphi",
        name: "Git and Delphi",
      },
      {
        path: "/git/GHD",
        name: "GitHub Desktop",
      },
    ],
  },
  {
    path: "/postgres",
    name: "PostgreSQL",
    icon: <SiPostgresql />,
    subRoutes: [
      {
        path: "/postgres/setup",
        name: "PG Maestro Install",
      },
      {
        path: "/postgres/howto",
        name: "How to's",
      },
    ],
  },
  {
    path: "/delphi",
    name: "Delphi",
    icon: <SiDelphi />,
    subRoutes: [
      {
        path: "/delphi/delphi-install",
        name: "Install/Setup",
      },
      {
        name: "TMS Software",
        subMenus: [
          {
            path: "delphi/tms/webcore",
            name: "TMS WEB Core",
          },
          {
            path: "/delphi/tms/biz",
            name: "TMS Biz",
          },
          {
            path: "/delphi/tms/xdata",
            name: "TMS XData",
          },
        ],
      },
      {
        name: "FMSoft uniGUI",
        subMenus: [
          {
            path: "/delphi/uniGUI/install",
            name: "Install",
          },
          {
            path: "/delphi/uniGUI/apachesetup",
            name: "Apache Setup",
          },
          {
            path: "/delphi/uniGUI/project",
            name: "Project",
          },
          // {
          //   path: "/delphi/uniGUI/ssl",
          //   name: "SSL",
          // },
          {
            path: "/delphi/uniGUI/hyperserver",
            name: "HyperServer",
          },
        ],
      },
      {
        path: "/delphi/fast-reports",
        name: "Fast Reports",
      },
    ],
  },
  {
    path: "/web",
    name: "Web Development",
    icon: <CgWebsite />,
    subRoutes: [
      {
        path: "/web/react-apps",
        name: "React Apps",
      },
      {
        path: "/web/vsc",
        name: "Visual Studio Code",
      },
      {
        path: "/web/tms",
        name: "TMS WEB core VSC",
      },
    ],
  },
  {
    path: "/linux",
    name: "Linux Servers",
    icon: <FaLinux />,
    subRoutes: [
      {
        path: "/linux/linux-commands",
        name: "Linux Commands",
      },
      {
        
        name: "Server Setup",
        subMenus: [
          {
            path: "/linux/linuxubuntu",
            name: "Linux-Ubuntu Initial Setup",
          },
          {
            path: "/linux/serverconfig",
            name: "Server Configuration",
          },
          {
            path: "/linux/datamigrate",
            name: "Database Migration",
          },
          {
            path: "/linux/users",
            name: "Users and Groups",
          },
        ],
      },
      {
        path: "/linux/mounted-drives",
        name: "Mounting Drives",
      },
      {
        path: "/linux/time-change",
        name: "Date/Time",
      },
      {
        path: "/linux/server-upgrade",
        name: "Server Upgrade",
      },
      {
        path: "/linux/backups",
        name: "Backups",
      },
      {
        path: "/linux/cron-jobs",
        name: "Cron-Jobs",
      },
      {
        path: "/linux/rsync",
        name: "Rsync",
      },
    ],
  },
  {
    path: "/windows",
    name: "Windows",
    icon: <BsWindows />,
    subRoutes: [
      {
        path: "/windows/wsl",
        name: "WSL",
      },
      {
        path: "/windows/wsl-dev",
        name: "Web Development",
      },
    ],
  },
  {
    path: "/info",
    name: "Info/About",
    icon: <BsInfoCircle />,
  },
];


  const Logo = styled.div`
    max-width: 200px;
    height: 80px;
    background: rgb(255,255,0);
    display: flex;
    align-items: center;
    line-height: 1;
    color: black;
    font-size: 20px;
    justify-content: center;
    img {
        width: 70px;
        height: 70px;
        color: white;
    }
    border-radius: 20px;
    cursor: pointer;
`;

const SideBar = ({ children, Footer }) => {
  const [isOpen, setIsOpen] = useState(true);
  //const [isFixed, setIsFixed] = useState(false);
  //const [openSubMenuCount, setOpenSubMenuCount] = useState(0);

  const showAnimation = {
   hidden: {
   width: 0,
   opacity: 0,
  transition: {
        duration: 0.5,
   },
    },
     show: {
       opacity: 1,
      width: "auto",
      transition: {
       duration: 0.5,
     },
   },
  };


  return (
    <>
      <div 
      className="main-container">
        <div
        className={`sidebar`}>
          <div className="top_section">
                  <a href="http://yrcgi.com/" target='_blank' rel="noreferrer">
                  <Logo>
                    <img src={ logoSVG } alt="logo"></img>
                      Yellow Ray Consulting
                  </Logo>
                  </a>
          </div>
          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }
              if (route.name === "Server Setup") {
                return (
                  <NavLink
                  to={route.path}
                  key={index}
                  className="link server-setup"
                >
                  <div className="icon">{route.icon}</div>
  
                    {isOpen && (
                      <motion.div className="link_text">
                        {route.name}
                      </motion.div>
                    )}
                  </NavLink>
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  
                    {isOpen && (
                      <motion.div
                        // initial="hidden"
                        /* animate="show" */
                        // exit="hidden"
                        className="link_text">
                        {route.name}
                      </motion.div>
                    )}
                  </NavLink>
                );
            })}
          </section>
        </div>

        <main>{children}</main>
      </div>
    </>
  );
          }
export default SideBar;