import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'
import CodeBlock from '../../../components/Codeblock';

const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
header {
    padding: 10px 0;
    text-align: center;
}
main {
    padding: 20px;
}
nav li {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
}
nav ul li a:hover {
    color: #111;
}
h1 {
  color: black;
  text-align: center;
}
h2 {
    padding: 20px;
    margin: 20px;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: circle;
}
img {
  
}
`

const code1 = `
procedure TUniServerModule.FirstInit;
begin
  InitServerModule(Self);
end;

procedure TUniServerModule.UniGUIServerModuleBeforeInit(Sender: TObject);
begin
  {$ifdef DEBUG}
    Options := Options + [soTerminateOnSession]; // Terminate the app server when all sessions are closed. Recommended for debug mode.
  {$endif}

  // Default settings for WSL/Windows ***Before SSL is set up properly
  SSL.Enabled := False; // Default SSL not enabled
  SSL.SSLPort := 0; // Default SSL port when not in SSL Mode
  UniServerModule.Port := 8077; // Running app from default port # 8077

  // Application settings for Linux
  {$ifdef Linux64}
    FrameworkFilesRoot := '/etc/fmsoft/unigui/unigui_runtime';
  // Make sure you add this if you plan on using more then 1 App on the same port 
    UniServerModule.UrlPath := '/appName';
    UniServerModule.Port := Port#;
    Options := Options + [soAutoRedirectHttps];
  {$endif}
end;`
const code2 = `
tar -xvf paserver.tar.gz
./install.sh`
const code3 = `
./paserver`
const code4 = `
./yourapplication`


const InitialProject = () => {
    return <>
          <SheetContainer>
          <header>
        <h1>Project Setup Guide</h1>
    </header>
    <nav>
        <ul>
            <li><a href="#getting-started">Getting Started</a></li>
            <li><a href="#main-module">Setting Up the Main Module</a></li>
            <li><a href="#server-module">Setting Up the Server Module</a></li>
            <li><a href="#compiling">Compiling the Project</a></li>
            <li><a href="#deploying-windows">Deploying to Windows</a></li>
            <li><a href="#deploying-linux">Deploying to Linux</a></li>
        </ul>
    </nav>
    <main>
        <section id="getting-started">
            <h2>Getting Started in Delphi</h2>
            <p>To get started with a uniGUI project in Delphi, follow these steps:</p>
            <ol>
                <li>Install the latest version of Delphi from the Embarcadero website.</li>
                <li>Open Delphi and create a new VCL Forms Application.</li>
                <li>Save your project in a designated folder.</li>
            </ol>
        </section>

        <section id="main-module">
            <h2>Setting Up the Main Module</h2>
            <p>The main module is the entry point of your application. Follow these steps to set it up:</p>
            <ol>
                <li>Create a new unit and name it <code>MainModule.pas</code>.</li>
                <li>In the unit, define the main form and its components.</li>
                <li>Add necessary event handlers for the components.</li>
            </ol>
        </section>

        <section id="server-module">
            <h2>Setting Up the Server Module</h2>
            <p>The server module handles the backend logic of your application. Here's how to set it up:</p>
            <ol>
                <li>Create a new unit and name it <code>ServerModule.pas</code>.</li>
                <li>Define the server-side logic and connect it with the main module.</li>
                <li>Configure the server settings and endpoints.</li>
                <li>The following is an example template of a Server Module:</li>
                <CodeBlock code={code1} language='Copy'></CodeBlock>
            </ol>
            
        </section>

        <section id="compiling">
            <h2>Compiling the Project</h2>
            <p>Before deploying, you need to compile your project:</p>
            <ol>
                <li>Select the appropriate target platform for your deployment:</li>
                    <ul>
                        <li>Windows 64-bit: Choose <strong>Target Platforms</strong> - <strong>Windows 64-bit</strong>.</li>
                        <li>Linux: Choose <strong>Target Platforms</strong> - <strong>Linux 64-bit</strong>.</li>
                    </ul>
                <li>In Delphi, click on <strong>Project</strong> in the menu and select <strong>Build All</strong>.</li>
                <li>Ensure there are no errors in the compilation process.</li>
                <li>Generate the executable files for deployment.</li>
            </ol>
        </section>

        <section id="deploying-windows">
            <h2>Starting App on Windows</h2>
            <p>Follow these steps to start your project on Windows(for testing purposes)</p>
            <ol>
                <li>After building the project with the proper target platform, go to <strong>Project</strong> and click <strong>Run</strong>.</li>
                <li>In your system tray, you will see a green box indicating the application is running.</li>
                <li>Open a web browser and navigate to <code>http://localhost:8077</code> to ensure you can see your application.</li>
            </ol>
        </section>

        <section id="deploying-linux">
            <h2>Deploying to Linux Using PA Server</h2>
            <p>To deploy your project to a Linux server using PA Server, follow these steps:</p>
            <ol>
                <li>Install PA Server on the target Linux server:
                    <ol>
                        <li>Download the PA Server installation package from the Embarcadero website.</li>
                        <li>Transfer the installation package to your Linux server using SCP, SFTP, or another file transfer method.</li>
                        <li>Connect to your Linux server via SSH.</li>
                        <li>Navigate to the directory where you transferred the installation package.</li>
                        <li>Extract the package and run the installer using the following commands:
                            <CodeBlock code={code2} language='Copy'></CodeBlock></li>

                    </ol>
                </li>

                <li>Open the PAServer Manager on the Linux server and start PA Server: 
                    <ol>
                    <li>After installation, navigate to the PA Server installation directory.</li>
                        <li>Start PA Server by running:
                            <CodeBlock code={code3} language='Copy'></CodeBlock></li>
                        <li>Follow the on-screen instructions to set up the server password and configuration.</li>
                    </ol>
                </li>
                <li>Connect Delphi IDE to PAServer:
                    <ol>
                        <li>Open Delphi IDE on your development machine.</li>
                        <li>Go to <strong>Tools</strong> - <strong>Options</strong> - <strong>Connection Profile Manager</strong>.</li>
                        <li>Create a new profile and enter the IP address and port number of your Linux server.</li>
                        <li>Enter the server password you configured in the previous step.</li>
                        <li>Test the connection to ensure it is successful.</li> 
                    </ol>
                </li>
                <li>Deploy the project to the Linux server via PA Server: 
                    <ol>
                        <li>In Delphi, select the Linux target platform for your project.</li>
                        <li>Under <strong>Project</strong>, click <strong>Deploy</strong>.</li>
                        <li>Delphi will compile the project and transfer the necessary files to the Linux server via PA Server.</li>
                    </ol>
                     </li>
                <li>Test the application on the Linux server to ensure it runs correctly:
                <ul>
                        <li>Connect to your Linux server via SSH.</li>
                        <li>Navigate to the directory where the application was deployed.</li>
                        <li>Run the application by executing the following command:
                            <CodeBlock code={code4} language="Copy" />
                        </li>
                        <li>Open a web browser and navigate to the application URL (e.g., <code>http://yourserver:8078/Path</code>).</li>
                        <li>Verify that the application is running correctly.</li>
                    </ul>
                </li>
            </ol>
        </section>
        </main>
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default InitialProject;