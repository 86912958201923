import React, { useState } from "react";
import './Linux.css';
import data from "./data.json";
import Footer from '../../components/Footer';

const LinuxTable = () => {
    const [contacts] = useState(data);
  
const ReadOnlyRow = ({ contact }) => {
    return (
      <tr>
        <td>{contact.command}</td>
        <td>{contact.explanation}</td>
        <td>{contact.example}</td>
      </tr>
     );
  };

    return ( 
      <div className="app-container">
        <form>
          <table>
            <thead>
              <tr>
                <th>Command</th>
                <th>Explanation</th>
                <th>Example</th>
              </tr>
            </thead>
              <tbody>
                {contacts.map((contact) => (
                  <ReadOnlyRow contact={contact}/>
                ))}
              </tbody>
            </table>
        </form>
        <Footer />
       </div>
       
       );
    };





export default LinuxTable;