import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,68,102);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
p {
  font-size: 25px;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: number;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
a {
  font-size: 20px;
}
`




const GitDesktop = () => {
    return <>
          <SheetContainer>
            <h1>GitHub Desktop</h1>
            <p>GitHub Desktop is a graphical user interface (GUI) for Git. It's developed by GitHub and provides an easy-to-use interface for managing your Git repositories.</p>
            <h2>Downloading and installing Github Desktop</h2>
            <ol>
                <li>If you have an account with Github you can easily connect it to GitHub Desktop and simplify your experience with GitHub.</li>
                <li>Go to the download page: <a href="https://desktop.github.com" target="_blank" rel="noreferrer">GitHub Desktop</a></li>
                <li>Click on Download for Windows</li>
                <li>Once downloaded go through the install setup and after completion Github Desktop will launch!</li>
            </ol>
            <h2>Authenticating</h2>
            <ol>
              <li>Under file menu, find options</li>
              <li>Under options window, click Accounts and then click Sign in beside Github.com</li>
              <li>Click Continue with Browser which will open your default browser.</li>
              <li>Type your account credentials and sign in. Then return to Github Desktop.</li>
            </ol>
          </SheetContainer>
          <Footer />
    </>
  };
  
  export default GitDesktop;