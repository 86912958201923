import React from "react";
import styled from 'styled-components';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';

const HomeContent=styled.section`
display: flex;
flex-direction: column

`

const Container=styled.section`
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: center;
      min-height: 100vh;
      h1 {
        font-size: 90px;
        margin: 80px;
        color: rgb(0,68,102);
        text-decoration: underline dotted;
      }
      p {
        font-size: 30px;
        margin: 25px;
      }
      `

const Quote=styled.div`
      align-self: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 30px;
      background-image: linear-gradient(to bottom, #080B7C, #19192A);
      padding: 10px;
      width: 1100px;
      border-radius: 50px;
      color: antiquewhite;
      `

      const SheetContainer=styled.section`
      width: 1000px;
      display: flex;
      flex-direction: column;
      align-self: center;
      height: 180px;
      border-radius: 5px;
      box-shadow: 0px 30px 40px -20px grey;
      padding: 30px;
      padding-bottom: 4.5rem;
      margin: 40px;
      background-color: white;
      `
const Home = () => {
    return <HomeContent>
      <Container>
      <h1>Welcome!</h1>
      <p>This is Yellow Ray Consulting's internal knowledge sharing site! A place for learning, teaching and brushing up on all the things we do here at YRCGI. Please be aware that any information found on this site has been useful to our Company but is not guaranteed to do the same for others. For more information see our <Link to='./Terms'>Terms of Use.</Link></p>
      <SheetContainer><h2>We are constantly adding, changing and updating this website. If information is missing, check back soon!</h2></SheetContainer>
    <Quote>
    <p>"To assist those on their path of discovery"- Ron Templeman</p>
    <p2>Inspired by the wisdom of Simon Sinek's 'Start with Why'. </p2>
    </Quote>
    </Container>
    <Footer />
    </HomeContent>
    
};


export default Home;