import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,0,0);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
  text-decoration: underline;
  margin: 20px;
  padding: 20px;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: number;
  line-height: 2.5;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
p {
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
`




const WSLDev = () => {
  const code1 = `sudo nano /etc/apache2/sites-available/app1.conf`;
    const code2 = `
<VirtualHost *:80>
    ServerAdmin webmaster@site1
    DocumentRoot /var/www/app1
    ServerName app1.local

    <Directory /var/www/app1>
        Options Indexes FollowSymLinks
        AllowOverride All
        Require all granted
    </Directory>

    ErrorLog \${APACHE_LOG_DIR}/site1_error.log
    CustomLog \${APACHE_LOG_DIR}/site1_access.log combined
</VirtualHost>
    `;
    const code3 = `sudo a2ensite app1.conf
sudo a2ensite app2.conf`
  const code4 = `WSL IP Address   app1.local
WSL IP Address   app2.local`
const code5 =`sudo ln -s /mnt/c/path/to/Webapp/ /var/www/app1`
const code6 =`sudo mkdir /var/www/app1
sudo mkdir /var/www/app2`  
const code7 =`sudo service apache2 restart` 
const code8 =`tail -n 50 /var/log/apache2/error.log`

    return <>
          <SheetContainer>
            <h2>Development environment in WSL</h2>
            <p>The following setup will allow you to develop your apps on your Windows machine using all your favourite Windows tools but host them on WSL2 using Apache2. Symbolic links can be used to link Windows development directories to web app directories and multiple apps can be hosted on the same port by simply configuring Virtual hosts in Apache2.</p>
            <ol>
                <li>Make sure WSL2 is properly set up, as well as having Apache2 installed inside your WSL2 instance.</li>
                <li>Setup and configure Apache virtual hosts, create seperate config files for each virtual host. These are usually found the the /etc/apache2/sites-available/ directory.<br/><CodeBlock code={code1}></CodeBlock></li>
                <li>This will open a new nano text editor where you would input something like this: 
                    <div>
                        <CodeBlock code={code2} language="Copy Code"></CodeBlock>
                    </div>
                </li>
                <li>Enable the Virtual hosts you just created:<CodeBlock code={code3}></CodeBlock></li>
                <li>Update Hosts File (on Windows): <br/>Edit C:\Windows\System32\drivers\etc\hosts on your machine and add entries similar to-<br/> <CodeBlock code={code4}></CodeBlock> </li>
                <li>Create Web app Directories or if your files are located on your Windows system create symbolic links: <br/><CodeBlock code={code5}></CodeBlock>or if they are saved to your WSL instance simply make new web directories and copy your files into them: <br/><CodeBlock code={code6}></CodeBlock> </li>
                <li>Restart Apache using command: <CodeBlock code={code7}></CodeBlock></li>
                <li>Test by visiting http://app1.local in your browser!</li>
                <li>Troubleshooting: Check the Apache2 error logs: <CodeBlock code={code8}></CodeBlock></li>
                </ol>
           
           
          </SheetContainer>
          <Footer />
    </>
  };
  
  export default WSLDev;