import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'
import { extentionsSVG } from '../../assets';
import { TMSCommandsSVG } from '../../assets';


const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
img {
  
}
`





const TMS = () => {
    return <>
          <SheetContainer>
            <h1>TMS Webcore in Visual Studio Code</h1>
            <h2>Install Notes</h2>
            <ul>
              <li>TMS WEB Core for Visual Studio Code requires version 1.50 or higher</li>
              <li>TMS WEB Core for Visual Studio Code works on Windows, macOS and Linux operating systems</li>
              <ol>
                <li>Within Visual Studio Code navigate to the extentions section</li>
                <li>Click on the three dots at the top, and select the option 'install from VSIX'</li>
                <img src= { extentionsSVG } alt="Language"></img>
                <li>Navigate to the .VSIX file included in the download. This will install everything you need.</li>
              </ol>
            </ul>
              <h2>Get Started</h2>
                  <ul>
                    <li>You should now see a new icon on the far left side tool bar </li>
                    <img src= { TMSCommandsSVG } alt="Language"></img>
                    <li>Under the new drop down, Click Create New Project..</li>
                  </ul>
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default TMS;