import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';


const SheetContainer=styled.section`
    width: 90%;
    border-top: 4px solid yellow;
    text-align: left;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 30px;
    background-color: back;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
      line-height: 40px;
    }
    h2 {
      text-align: center;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`

const ServerSetup = () => {
    return <>
      
    <SheetContainer>
    <h2>Initial Setup</h2>
          <ul>
            <li>Select your server hardware</li>
            <li>Select server operating system: in this case Linux Ubuntu Server.</li>
            <li>If server does not come with operating system already installed, install it from a DVD or USB.</li>
            <li>A minimal set of functionality is installed on the OS, so we can install everything we need ourselves.</li>
            <li>Prompt for Language- English -Press Enter</li>
            <li>Keyboard Layout- English -Press Enter</li>
            <li>References:<br /> Download-<a href="https://www.ubuntu.com/download/server" target="blank">https://www.ubuntu.com/download/server</a> <br /> Installation- <a href="https://help.ubuntu.com/lts/serverguide/installation.html" target="blank"> https://help.ubuntu.com/lts/serverguide/installation.html</a> <br /> Tutorial- <a href="https://tutorials.ubuntu.com/" target="blank">https://tutorials.ubuntu.com/</a></li>
            <li>Try logging in with root user and root password</li>
            <li>Upgrade the operating system : <mark>apt-get upgrade</mark> and <mark>apt-get update</mark></li>  
            </ul>
            </SheetContainer>
    <Footer/>
    </>
  };
  
  export default ServerSetup;