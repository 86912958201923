import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import pgMInstall, { pgMInstallMP4 } from '../../assets'

const SheetContainer=styled.section`
    width: 90%;
    border-top: 4px solid orange;
    text-align: left;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    text-align: center;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
    }
    p {
      font-size: 20px
    }
    mark {
      background-color: black;
      color: white;
      padding: 8px;
      font-style: italic;
    }
`


const Notes = () => {
    return <>
      <SheetContainer >
    <h1>PgMaestro Install</h1>
    <p>Purchase a license for a copy of PgMaestro. The new license will be stored in a folder along with the license key file provided with purchase. The license should arrive within 6 hours of purchase.<br /> You can register the software during installation or after install, You have 30 days to register before the software quits.</p>
    <video src={pgMInstallMP4} width="750" height="500" controls>
     </video>
    </SheetContainer >
    <Footer />
    </> 
    
  };
  
  export default Notes;