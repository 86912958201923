import logo from "./logo.png";
import Cronjob from './Cronjob.png';
import Contactform from './contact.jpg';
import Ron from './Ron templeman.jpg';
import Vlad from './IMG_20220907_223307_824[227].jpg';
import Chrissy from './Image[226].jpeg';
import Upgrade from './upgrade.img.jpg';
import Update from './update.img.jpg';
import Autoremove from './autoremove.img.jpg';
import Who from './whocommand.img.jpg';
import Language from './first.png';
import Already from './alreadyhave.png';
import Agreement from './agreement.png';
import License from './license.png';
import Theme from './chooseColor.png';
import Platforms from './Platforms.png';
import Additional from './AdditionalOptions.png';
import Setup1 from './tmswebcoresetup(1).png';
import Setup2 from './tmswebcoresetup(2).png';
import Setup3 from './tmswebcoresetup(3).png';
import Setup4 from './tmswebcoresetup(4).png';
import Setup5 from './tmswebcoresetup(5).png';
import Setup6 from './tmswebcoresetup(6).png';
import Setup7 from './tmswebcoresetup(7).png';
import Setup8 from './tmswebcoresetup(8).png';
import Setup9 from './tmswebcoresetup(9).png';
import Setup10 from './tmswebcoresetup(10).png';
import Setup11 from './tmswebcoresetup(11).png';
import Setup12 from './tmswebcoresetup(12).png';
import Setup13 from './tmswebcoresetup(13).png';
import Setup14 from './tmsoptions.png';
import Extentions from './TMSInstallExtentions.png';
import TMSCommands from './TMSCommands.png';
import pgMInstall from './PGInstall.mp4';


export const cronSVG = Cronjob;
export const logoSVG = logo;
export const contactSVG = Contactform;
export const ronSVG = Ron;
export const vladSVG = Vlad;
export const chrissySVG = Chrissy;
export const upgradeSVG = Upgrade;
export const updateSVG = Update;
export const autoSVG = Autoremove;
export const whoSVG = Who;
export const languageSVG = Language;
export const alreadySVG = Already;
export const agreementSVG = Agreement;
export const licenseSVG = License;
export const themeSVG = Theme;
export const platformsSVG = Platforms;
export const additionalSVG = Additional;
export const setup1SVG = Setup1;
export const setup2SVG = Setup2;
export const setup3SVG = Setup3;
export const setup4SVG = Setup4;
export const setup5SVG = Setup5;
export const setup6SVG = Setup6;
export const setup7SVG = Setup7;
export const setup8SVG = Setup8;
export const setup9SVG = Setup9;
export const setup10SVG = Setup10;
export const setup11SVG = Setup11;
export const setup12SVG = Setup12;
export const setup13SVG = Setup13;
export const setup14SVG = Setup14;
export const extentionsSVG = Extentions;
export const TMSCommandsSVG = TMSCommands;
export const pgMInstallMP4 = pgMInstall;