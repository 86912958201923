import React, { useState, useRef } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { IoIosCopy, IoIosCheckmarkCircleOutline } from 'react-icons/io'
//import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import styled from 'styled-components';

// Define the styled component here
const StyledCodeBlock = styled.div`
  position: relative;
  width: 100%;
  
`;


const CodeBlock = ({ code, language }) => {
  const [copied, setCopied] = useState(false);
  // const [minHeight, setMinHeight] = useState('200px');
  const codeRef = useRef(null);

  // useEffect(() => {
  //   // Calculate number of lines in code snippet
  //   const lines = code.split('\n').length;

//     // Adjust minimum height dynamically based on number of lines
//     const minHeight = `${lines * 20}px`; // Adjust 20px as needed for line height
//     setMinHeight(minHeight);
// }, [code]);
  
  const notify = () => {
    toast(<ToastDisplay className='bg-info m-10' />)
    copy()
  }

  function ToastDisplay () {
    return (
            <div className='m-0'>
                <p className='text-md'>Copied to clipboard !</p>
            </div>
    )
  }
  const copy = () => {
    console.log('Copied!')
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <StyledCodeBlock>
        <div className="position-relative w-75">
            <CopyToClipboard text={code} onCopy={(copied) => notify() }>
            <button className="btn btn-outline-secondary position-absolute top-0 end-0 p-2">
                <span className='m-1 pb-1 fs-6'>{language}</span>
                
                        {copied ? 
                            <IoIosCheckmarkCircleOutline className="fs-5 me-1 text-success" />
                         :  <IoIosCopy className="fs-5 me-1" />}
            </button>
            </CopyToClipboard>
            <SyntaxHighlighter
                className='m-0 pb-10 rounded'
                language={language}
                style={vs2015}
                wrapLines={true}
                wrapLongLines={true}
                showLineNumbers={false}
                showInlineLineNumbers={false}
                ref={codeRef}
            >
            
                {code}
            </SyntaxHighlighter>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                closeButton={false}
                limit={1}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="dark" />
        </div>
        </StyledCodeBlock>
  );
};
export default CodeBlock;