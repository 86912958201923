import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'
import CodeBlock from '../../../components/Codeblock';


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
p {
  font-size: 20px;
  margin: 20px;
}
img {
  
}
`

const ApacheSetup = () => {
  const code1 = `
sudo a2enmod proxy
sudo a2enmod proxy_http
sudo a2enmod headers
sudo a2enmod rewrite`

  const code2 = `
<VirtualHost *:80>
  ServerName yourdomain.com
  ServerAlias www.yourdomain.com

  # Proxy settings
  ProxyRequests Off
  ProxyPass / http://localhost:Port/ <!-- Forward requests to the application -->
  ProxyPassReverse / http://localhost:Port/ <!-- Rewrite headers for reverse proxy -->

  # Additional settings (optional)
  # Include any additional headers or configurations as needed

</VirtualHost>`
const code3 = `
sudo ufw allow 'Apache Full'
sudo ufw deny Port#
sudo ufw enable`
const code4 = `
sudo systemctl restart apache2`
const code5 =`
sudo nano /etc/apache2/sites-available/your-site.conf`
const code6 = `
sudo a2ensite your-site.conf 
sudo systemctl reload apache2`
const code7 = `
sudo netstat -tuln | grep Port#`
const code8 = `
<VirtualHost *:80>
  ServerName yourdomain.com
  ServerAlias www.yourdomain.com

  # Redirect all HTTP requests to HTTPS
  RewriteEngine on
  RewriteCond %{SERVER_NAME} =yourdomain.com [OR]
  RewriteCond %{SERVER_NAME} =www.yourdomain.com
  RewriteRule ^ https://%{SERVER_NAME}%{REQUEST_URI} [END,NE,R=permanent]
</VirtualHost>`
const code9 = `
sudo apt install certbot python3-certbot-apache
sudo certbot --apache`;
const code10 = `
<VirtualHost *:443>
  ServerName yourdomain.com
  ServerAlias www.yourdomain.com

  SSLEngine on
  SSLCertificateFile /etc/letsencrypt/live/yourdomain.com/fullchain.pem
  SSLCertificateKeyFile /etc/letsencrypt/live/yourdomain.com/privkey.pem
  Include /etc/letsencrypt/options-ssl-apache.conf

  </VirtualHost>`
  return (
      <>
          <SheetContainer>
              <h1>Guide to Set Up Apache as Reverse Proxy (HTTP Only)</h1>

              <h3>Enable Required Apache Modules</h3>
              <CodeBlock code={code1} language="Copy" />

              <h3>Configure Apache Virtual Host for HTTP</h3>
              <p>Create or edit the Apache virtual host configuration file (<code>your-site.conf</code> for example):</p>
              <CodeBlock code={code5} language="Copy" />
              <p>Add the following configuration:</p>
              <CodeBlock code={code2} language="Copy" />
              <p>Save and exit the editor.</p>

              <h3>Enable the New Site Configuration</h3>
              <CodeBlock code={code6} language="Copy" />

              <h3>Verify Your Application is Running</h3>
              <p>Ensure your uniGui application is running and listening on port 8078:</p>
              <CodeBlock code={code7} language="Copy" />
              <p>You should see output indicating that your application is listening on port 8078.</p>

              <h3>Access Your Application</h3>
              <p>Open a web browser and navigate to <code>http://yourdomain.com</code>. Your uniGui application should now be accessible via HTTP through Apache's reverse proxy configuration.</p>

              <h3>Secure the Standalone Server:</h3>
    
        
            <h4>Prevent Direct Access:</h4>
            <p>Use a firewall to block external access to port 8078. This ensures only Apache can communicate with the standalone server on the internal network.</p>
        
        
            <h4>Firewall Commands:</h4>
            <CodeBlock code={code3} language='Copy'></CodeBlock>
        
            <h4>Apply Changes:</h4>
            <p>Restart Apache to apply the changes.</p>
            <CodeBlock code={code4} language='Copy'> </CodeBlock>

            <p>Allow 'Apache Full':</p>
            
            <h4>Purpose:</h4> <p>Allows traffic on both HTTP (port 80) and HTTPS (port 443) for Apache.</p>
            <h4>Effect:</h4> <p>Ensures your Apache server can receive traffic on these ports, essential for serving web content over HTTP and HTTPS.</p>
            <h4>Disallow Direct Access to requested Port:</h4>
            <p>Ensures the application is accessed only through the reverse proxy, enforcing SSL/TLS.</p>

            <h1>Setting up https</h1>

            <h4>Obtain an SSL Certificate</h4>
            <p>If you haven’t already obtained an SSL certificate for your domain, use Certbot to get one:</p>
            <CodeBlock code={code9} language='Copy'></CodeBlock>
            <p>Follow the prompts to obtain and install the certificate.</p>

            <h4>Configure Apache Virtual Host for HTTPS</h4>
            <p>Add the following configuration to your Apache virtual host configuration file (<code>your-site.conf</code> for example):</p>
            <CodeBlock code={code10} language='Copy' />
            <p>Save and exit the editor.</p>

            <h4>Redirecting to https</h4>
            <p>Once you have gotten https working this is recommended:</p>
            <CodeBlock code={code8} language='Copy'></CodeBlock>
          </SheetContainer>
            <Footer />
    </>
     );
    };
    
  
  export default ApacheSetup;