import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';
import {upgradeSVG} from '../../assets';
import {updateSVG} from '../../assets';
import {autoSVG} from '../../assets';
import {whoSVG} from '../../assets';


const SheetContainer=styled.section`
    width: 100%;
    border-top: 4px solid yellow;
    text-align: left;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 30px;
    background-color: back;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
      line-height: 2.5;
    }
    h2, h1 {
      text-align: center;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`




const ServerUpgrade = () => {
    return <>
    <SheetContainer>
       <h1>Linux server upgrades</h1>
       <ul>
        <li>login as root user</li>
        <li>Switch to superuser: <mark>sudo su</mark></li>
        <li>Change folder (optional): <mark>cd ~</mark> moves to /root folder</li>
        <li>Run command: <mark>apt-get upgrade</mark></li>
        <li>If prompted [Y/n] enter Y to continue, see image below</li>
        <img src= { upgradeSVG } alt="Upgrade"></img>
        <li>Run command: <mark>apt-get update</mark> Returns something like below image.</li>
        <img src= { updateSVG } alt="Update"></img>
        <li>Repeat command: <mark>apt-get upgrade</mark></li>
        <li>To remove no longer needed packages run command: <mark>apt autoremove</mark> </li>
        <img src= { autoSVG } alt="Autoremove"></img>
        <li>To check disc space run command: <mark>df -kh</mark></li>
        <li>If reboot is required, first check who is logged in by runnning command: <mark>who</mark> </li>
        <img src= { whoSVG } alt="Who"></img>
        <li>To reboot server run command: <mark>reboot now</mark> , for help on reboot type run command: <mark>reboot -h</mark></li>
        <li>Best practice to reboot the systems after you have backed up the data. In case there is a reboot problem.</li>
        <li>Server will shut down and take atleast a few minutes to come back online. Check to make sure it comes back online.</li>
       </ul>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default ServerUpgrade;