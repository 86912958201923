import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'


const SheetContainer=styled.section`
    width: 90%;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    line-height: 40px;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
    }
    h1, h2, h4 {
      text-align: center;
    }
    p {
        font-size: 20px;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
    
`







const Rsync = () => {
    return <>
    <SheetContainer>
       <h2>What is Rsync?</h2>
       <h4>Rsync is a tool that is used to sync files between two servers. Rsync allows you to be very specific on how and what your are transfering. It works very well along side Cron Jobs.</h4>
       <h2>How it works</h2>
       <h4>The format of an Rsync may look intimidating but when you break it down it is simply: <mark>rsync [option] [origin] [destination]</mark></h4>
       <ul><h2>Common options:</h2>
        <li>-a : runs archive mode</li>
        <li>-v : runs verbose version of command</li>
        <li>-h : rsync help</li>
        <li>-u : runs incremental backup, only updates file that got changed</li>
        <li>-e : tells rsync what shell to use</li>
        <li>-r : runs command recursively in directory</li>
        <li>-progress or -P : shows progress of transfer</li>
       </ul>

       <p>You can easily combine options</p>
       <p> -a, -v, -h would combine as -avh</p>
       <p>Example: rsync -avh  /home/user/document username@ipAddress:/home/user/public_html/backup_destination</p>
    </SheetContainer>
    < Footer />
    </>
  };
  
  export default Rsync;