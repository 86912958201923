import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
img {
  
}
`





const Biz = () => {
    return <>
          <SheetContainer>
            <h1>TMS Biz Package</h1>
            <h2>XData</h2>
            <p>Delphi only product.</p>
            <h2>ORM and Database Development</h2>
            <h3>Aurelius</h3>
            <h3>Data Modeler</h3>
            <h3>RemoteDB</h3>
            <h2>TMS Software Tools</h2>
            <h3>Scripter</h3>
            <h3>Sphinx</h3>
            <h3>Logging</h3>
            <h3>Echo</h3>
            <h2>TMS Subscription Manager</h2>
            <h3>Order of install</h3>
            <ol>
              <li>TMS Business Core Library</li>
              <li>TMS Aurelius</li>
              <li>TMS Sparkle</li>
              <li>XData</li>
              <li>TMS Echo</li>
              <li>TMS Logging</li>
              <li>Sphinx, RemoteDB, Scripter - In any order afterwards</li>
            </ol>
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default Biz;