import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';


const SheetContainer=styled.section`
    width: 98%;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    h1 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 40px
    }
    ol, li {
        list-style-type: number;
        line-height: 45px;
        font-size: 20px;
        padding: 10px;
        margin: 20px;
    }
    mark {
        background-color: black;
        border-radius: 6px;
        color: white;
        padding: 7px;
        font-style: italic;
      }
`

const Mount = () => {
  return <>
  <SheetContainer>
     <h1>Mounting a drive on a Linux server</h1>
     <ol>
        <li>Physically connect the new drive to the server. Ensure that it is properly connected and powered on</li>
        <li>Identify the new drive you have just connected by using command <mark>lsblk</mark>, this command will list all available block devices. The drive will typically be listed as /dev/sdX, where X would be a letter indicating the drive. You may need to identify drive by its size.</li>
        <li>Partition and format the new drive by using the following command: <mark>mkfs.ext4 -j -L DriveName /dev/sdX</mark><br/>
        - mkfs.ext4: is the command to create an ext4 file system. mkfs stands for "make file system," and ext4 refers to the fourth extended file system, which is a commonly used file system in Linux.<br/>
        - '-j': Enables the use of an ext3-style journal for the ext4 file system. The journal helps improve the file system's consistency and recoverability in the event of a system crash or power failure.<br/>
        - '-L': This option is used to specify a label for the file system.<br/>
        - /dev/sdX represents the partition identifier. <br/>
        - Replace X with appropriate letter corresponding to your drive.<br/></li>
        <li>Choose a mount point: Decide where you want to mount the new drive. You can choose an existing empty directory or create a new directory. Example: <mark>sudo mkdir /mnt/newdrive</mark> </li>
        <li>Mount the new drive to the chosen mount point using the 'mount' command: <mark>sudo mount /dev/sdX /mnt/newdrive</mark></li>
        <li>If you want the drive to be automatically mounted at boot, you'll need to add an entry to the /etc/fstab file. Open the file with a text editor(Nano), and add a line similar to the following: <mark>/dev/sdX /mnt/newdrive  ext4  defaults  0  0</mark> <br/>
        - In place of defaults you can also be more specific with your mount options.<br/> Example: <mark>auto nosuid,nodev,nofail,x-gvfs-show,x-gvfs</mark><br/>
        - auto: Indicates that the file system should be automatically mounted at boot time.<br/>
        - nosuid: Prevents the execution of setuid and setgid bits on files within the file system, this can enhance security.<br/>
        - nodev: Disables the execution of device nodes and helps to enhance security by preventing users from creating or accessing device files within the mounted file system.<br/>
        - nofail: Tells the system not to halt the boot process or display error messages if the file system fails to mount. Instead, it continues the boot process, treating the failed mount as non-critical. Better used for non-essential file systems.</li>
     </ol>
  </SheetContainer>
  < Footer />
  </>
};

  
  export default Mount;