import React from "react";
import styled from 'styled-components';
import Footer from '../../../components/Footer'


const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid blue;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 40px;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
}
h3 {
    color: black; 
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
}
p, a {
    font-size: 20px;
    margin: 20px;  
}
img {
  
}
`





const GUIInstall = () => {
    return <>
          <SheetContainer>
            <h1>uniGUI Installation</h1>
            <h3>References</h3>
                <a href="https://www.unigui.com/doc/online_help/index.html" target="_blank" rel="noopener noreferrer">
                Click here to view the Documentation</a>

            <h3>Installation Folder</h3>
                <p>C:\Program Files (x86)\FMSoft\Framework\uniGUI</p>

            <h3>Installation Instructions</h3>
                <p>Before installing a new version, remove all design packages from Delphi
		        and uninstall uniGUI from Windows Program Add/Remove.</p>
                <p>There are Un-Install programs in this folder to use to uninstall previous versions
			    C:\Program Files (x86)\FMSoft\Framework
                <ol>
                    <li>unins000.exe- Removes UniGui Professional and all its components</li>
                    <li>unins001.exe- Removes UniGui Theme Pak </li>
                    <li>unins002.exe- Removes UniGui Documentation</li>
                </ol>
				All subfolders should now be empty.</p>

                <p>After re-compiling an application with this new version, the "ext" folder
	            must be re-deployed to PCs running the new version of your application, or
	            you can simply re-install the newly introduced Ext JS runtime package which
	            can be downloaded from the Downloads page.</p>

                <ol>
                  <li>Download the latest uniGUI Setup from the customer portal. Current Version: V1.90.0.1583<br/>
                  You will notice that there are two Setup versions:<br/><br/>
                  FMSoft_uniGUI_Edition_0.XX.0.YYYY.exe<br/>
                  This is the one which will be installed on each developer PC for development purposes.<br/><br/>
                  FMSoft_uniGUI_Edition_runtime_0.XX.0.YYYY.exe<br/>
				  This one is only for deployment and will be installed on any server PC hosting your uniGUI apps.</li>
                  <li>Run the exe file as administrator- FMSoft_uniGUI_Professional_0.XX.0.YYYY.exe and enter the following information. Make sure the email address is exactly the same as the email address registered in the customer portal.
                    <li>Name</li>
                    <li>Email</li>
                    <li>Company Name</li>
                    Then accept license agreement</li>
                  <li>Enter Portal Password and go find License Key</li>
                  <li>Select an installation folder- C:\Program Files (x86)\FMSoft\Framework</li>
                  <li>Select the Delphi version(s) for which you want to install uniGUI
                    <li>Delphi 2007</li>
                    <li>Delphi 10.2 Tokyo</li>
                    <li>Delphi 10.3 Rio</li>
                    <li>Delphi 10.3 Rio (Linux 64)</li>
                    <li>Delphi 10.4 Sydney</li>
                    <li>Delphi 10.4 Sydney (Linux 64)</li>
                    <li>Delphi 11.0 Alexandria</li>
                  </li>
                  <li>Press Install to start, takes some time.</li>
                  <li>Then click Finish</li>
                
                  <li>Start Delphi and open the project group for your version.</li>
                  <li>Navigate to Project Manager, there are 11 Delphi packages. Build all packages starting from SynEdit_Rxxxx.bpl</li>
                  <li>You can then run the IDE's Build All command for the entire project group to build all the packages.</li>
                  <li>After building all, you must install the design time packages by right-clicking and selecting Install in the following order:
                    <ol>
                        <li>SynEdit_D20xx.bpl</li>
                        <li>uniGUIxxdcl.bpl</li>
                        <li>uniGUIxxChartdcl.bpl</li>
                        <li>uniGUIxxmdcl.bpl (Plus/Complete Editions only)</li>
                    </ol>
                   <li>A Ton of new components will be installed and then the installation is complete!</li>
                </li>
                </ol>
                
                <h3>Runtime install</h3>
                <ol>
                <li>Now run as administrator the Runtime exe -FMSoft_uniGUI_runtime_0.XX.0.YYYY.exe</li><br/>
                Note- runtime should not be installed with the framework.
                </ol>

                <h3>Running uniGUI Demos</h3>
                <h4>Demos are a great way to quickly learn uniGUI basics. They cover both standard and advanced features.</h4>
                <h4>Most demos work with all supported Delphi versions, though some may not compile on older versions due to language incompatibilities.</h4>
                <ol>
                    <li>Open Delphi IDE</li>
                    <li>Go to Files Open and navigate to the uniGUI demo location, usually found at install folder\FMSoft\Framework\uniGUI\Demos\</li>
                    <li>Select the AllFeaturesDemo folder and open 'mdemo.dpr'</li>
                    <li>In the IDE, select Project then Build mdemo and build the project</li>
                    <li>After a successful build, press Run. A new icon will appear in the system tray</li>
                    <li>If prompted, grant firewall permission for the uniGUI application to listen to its dedicated port.</li>
                    <li>Open a compatible browser, In the browser address bar, type http://localhost:8077</li>
                </ol>
                
                
          </SheetContainer>
            <Footer />
    </>
  };
  
  export default GUIInstall;