import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer'
import CodeBlock from '../../components/Codeblock';

const SheetContainer=styled.section`
width: 98%;
display: flex;
flex-direction: column;
border-top: 4px solid rgb(0,0,0);
min-height: 100vh;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1, h2 {
  color: black;
  text-align: center;
  text-decoration: underline;
  margin: 20px;
  padding: 20px;
}
li {
  font-size: 20px;
  margin: 20px;
  list-style: square;
  line-height: 2;
}
mark {
  background-color: black;
  border-radius: 6px;
  color: white;
  padding: 7px;
  font-style: italic;
}
p {
  font-size: 22px;
}
`





const WSL = () => {
  const code1 = `wsl --install`
  const code2 = `wsl -l`
  const code3 = `ubuntu config --default-user root`
  const code4 = `ubuntu`
  const code5 = `passwd 'username'`
  const code6 = `ubuntu config --default-user 'username'`
  const code7 = `exit`
  const code8 = `sudo apt install apache2`
  const code9 = `sudo nano /etc/apache2/ports.conf`
  const code10 = `sudo service apache2 restart`
  const code11 = `sudo nano /etc/apache2/sites-available/000-default.conf`
  const code12 = `sudo chown -R $USER:$USER /var/www`
  const code13 = `sudo chmod -R 755 /var/www`
  const code14 = `sudo nano /etc/apache2/envvars`
  const codepg1 = `sudo apt install postgresql postgresql-contrib`
  const codepg2 = `psql --version`
  const codepg3 = `sudo passwd postgres`
  const codepg4 = `sudo service postgresql start`
  const codepg5 = `psql`
  const codepg6 = `sudo service postgresql status`
  const codepg7 = `sudo service postgresql stop`
    return <>
          <SheetContainer>
            <h1>Windows Subsystem for Linux</h1>
           <h2>About</h2>
           <p>Windows Subsystem for Linux (WSL) allows you to run a Linux operating system alongside your regular Windows operating system.</p>
           <p> You can use the Linux command line (Terminal) to run Linux programs and tools just like you would in a regular Linux environment. WSL allows you to access your Windows files from Linux and vice versa, making it easy to work with files between the two systems. It is especially useful for developers who want to test their code on a Linux environment without needing seperate physical machine. On top of all this WSL runs in its own little space so doesn't affect your windows setup. </p>
           <h2>Install</h2>
           <ol>
            <li>WSL comes with the Windows operating system, but it must be enabled and a Linux distribution must be installed before it can be used.</li>
            <li>If running a more recent build of Windows(20262+) and as long as there isn't a version of WSL already installed on the system, there is now a simple command to install all necessary components and everything else needed for WSL2: <CodeBlock code={code1}></CodeBlock></li>
            <li>This command installs the latest Linux kernel, sets WSL2 as the default version, installs Ubuntu distribution and places your Linux distributon on your C: drive, after a reboot of your system open a terminal, enter wsl and it will prompt you to create an admin username and password.</li>
           </ol>
           <h2>Upgrading WSL 1 to WSL 2</h2>
           <p>There are times wsl is already installed on your system. When you go and check the version of wsl you may see it is a very old version 1 of WSL. The commands given in the documentation have not worked for us on occasion but we have figured out that enabling a feature called hypervisor helps.</p>
           <ol>
            <li>Select Turn Windows Features on or off.</li>
            <li>Select 
		Virtual Machine Platform
		Windows Hypervisor Platform
	Click OK.
	
Reboot the computer and you are ready to use wsl 2.</li>
           </ol>
           <h2>Commands</h2>
           <ol>
            <li><mark>wsl</mark> or <mark>ubuntu</mark>(if using as distro): Launches a new Linux terminal session.</li>
            <li><mark>wsl --set-version 'Distro' 2</mark>: Converts an installed WSL1 distribution to WSL2 (replace 'Distro' with the name of your distribution).</li>
            <li><mark>wsl --set-default-version 2</mark>: Sets WSL2 as the default version for all new Linux distributions.</li>
            <li><mark>wsl --list --verbose</mark> or <mark>wsl -l -v</mark>: Lists all installed WSL distributions with their versions and status.</li>
            <li><mark>wsl --shutdown</mark>: Shuts down all running WSL instances.</li>
            <li><mark>wsl --user 'Username'</mark>: Changes the default user for a distribution.</li>
            <li><mark>wsl --set-default-memory 'Distro' 'AmountInMB'</mark>: Sets the memory limit for a distribution.</li>
            <li><mark>wsl --update</mark>: Checks for updates to WSL 2.</li>
            <li><mark>wsl --help</mark>: Shows a list of options and commands available with WSL.</li>
            <li><mark>wsl --unregister 'DistroName'</mark>: Removes distribution from WSL.</li>
           </ol>
           <ol>
            <h3>Package Management</h3>
            <li><mark>sudo apt update</mark>: Updates the package lists for available software updates.</li>
            <li><mark>sudo apt upgrade</mark>: Upgrades installed packages to their latest versions.</li>
            <li><mark>sudo apt autoremove</mark>: Removes unnecessary packages that were installed as dependencies but are no longer needed.</li>
           </ol>
           <ol>
            <h3>File and Directory Management</h3>
            <li><mark>cd</mark>: Changes the current directory.</li>
            <li><mark>mkdir</mark>: Creates a new directory.</li>
            <li><mark>ls</mark>: Lists files and directories.</li>
           </ol>
           <ol>
            <h3>Text editing and viewing</h3>
            <li><mark>nano 'filepath'</mark> or vim: Text editors for creating and editing files.</li>
            <li><mark>cat 'fileName'</mark>: Displays the content of a file.</li>
           </ol>
           <h2>Resetting password</h2>
           <ol>
            <li>Open Windows Terminal and run the following command to get the correct distro name.: <CodeBlock code={code2}></CodeBlock></li>
            <li>In the Windows terminal use this command to switch default user to root: <CodeBlock code={code3}></CodeBlock></li>
            <li>Launch WSL distro this will log you in as root user. Example: <CodeBlock code={code4}></CodeBlock></li>
            <li>Change the password for your username: <CodeBlock code={code5}></CodeBlock></li>
            <li>Change the default user back to your username: <CodeBlock code={code6}></CodeBlock></li>
            <li>Exit termianl session using command: <CodeBlock code={code7}>exit</CodeBlock></li>
            <li>When you open WSL2 again after these steps it should prompt you to enter your new password.</li>
           </ol>
           <h2>Accessing files between WSL2 Ubuntu and Windows</h2>
           <ol>
            <li>To access Windows from Linux: cd /mnt/c/</li>
            <li>To open WSL in current Windows directory: <mark>wsl or wsl -d ubuntu</mark></li>
            <li>To access Linux from Windows explorer: \\wsl.localhost</li>
            <li>To open current Linux path from Windows: <mark>explorer.exe .</mark></li>
            <li>You can edit, copy or remove any file or folder in your chosen distro directly from Windows using Windows applications.</li>

           </ol>
           <h2>Installing Apache2 Web Server</h2>
           <ol>
            <li>Make sure you system is updated and upgraded</li>
            <li>Use command- <CodeBlock code={code8}></CodeBlock></li>
            <li>Since Apache is usually set to listen on port 80 and you may already have something else listening on port 80 on your Windows system, best practice is to set Apache on WSL2 to listen to another port such as 8081.</li>
            <li>Navigate to your ports.conf file. There you can change the port Apache2 will listen to. <CodeBlock code={code9}></CodeBlock></li>
            <li>Restart Apache: <CodeBlock code={code10}></CodeBlock></li>
           </ol>
           <ol>
            <li>For convenience change the default path for your web projects to /var/www</li>
            <li>Then change DocumentRoot within your sites-available configuration file from /var/www/html to /var/www <CodeBlock code={code11}></CodeBlock> </li>
            <li>Next change owner and permissions for this path:<CodeBlock code={code12}></CodeBlock> <CodeBlock code={code13}></CodeBlock></li>
            <li>Lastly(Optional), change Apache to run as your username and not the default apache user(www-data)</li>
            <li>Navigate to your enviroment variables file, in this file you can change the apache_run_user so you have full access: <CodeBlock code={code14}></CodeBlock></li>
           </ol>
           <h2>Installing PostgreSQL</h2>
           <ol>
            <li>Again, make sure everything is up to date. Then use command: <CodeBlock code={codepg1}></CodeBlock></li>
            <li>To confirm it installed use command: <CodeBlock code={codepg2}>psql --version</CodeBlock></li>
            <li>First, the default admin user "postgres" will need a password setup, use command: <CodeBlock code={codepg3}></CodeBlock> you will then be prompted to enter a new password twice.</li>
            <li>Exit terminal then re-enter and write command: <CodeBlock code={codepg4}></CodeBlock></li>
            <li>To open the postgres shell use: <CodeBlock code={codepg5}>psql</CodeBlock> This will change what the command line looks like and you'll be in the psql shell.</li>
            <li>Other important commands: <CodeBlock code={codepg6}></CodeBlock> and <CodeBlock code={codepg7}></CodeBlock></li>
            
           </ol>
           <h2>Docker</h2>
           <ol>
            <li>Docker is a software platform that simplifies managing and distributing fast and lightweight applications. It enables you to package apps with all dependencies needed to run smoothly on any Linux distro. </li>
            <li>Dockerfiles: Configuration files for building a docker image.</li>
            <li>Docker images: Read only templates that contain all you need to run your application. Docker images cannot be edited after creation and the images themselves don't actually run, they create and run containers. </li>
            <li>Docker containers: Where the actual application runs. Containers are isolated from the system which makes for a secure place to run applications that connect to databases or that need secure access.</li>
           </ol>
           <h3>Docker install</h3>
           <ol>
            <li>Before downloading Docker Desktop make sure you have latest version of WSL2, verify you are using Windows 10 or 11 latest versions.</li>
            <li>Next download the latest executable installer for Docker Desktop through the <a href="https://docs.docker.com/get-docker/" target="blank">Docker website</a>.</li>
            <li>Follow installer intructions.</li>
            <li>When completed, Start Docker Desktop through the Windows start Menu.</li>
            <li> Go to settings and find the General tab, then select Use WSL 2 based engine(this options may already be turned on).Then go to resources under WSL integration make sure Ubuntu is checked.</li>
            <li>Click Apply and Restart!</li>
           </ol>
           
           
          </SheetContainer>
          <Footer />
    </>
  };
  
  export default WSL;