import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/Footer';



const SheetContainer=styled.section`
width: 100%;
display: flex;
flex-direction: column;
border-top: 4px solid red;
height: auto;
border-radius: 5px;
box-shadow: 0px 30px 40px -20px grey;
padding: 30px;
padding-bottom: 4.5rem;
margin: 20px;
background-color: white;
h1 {
  color: black;
  text-align: center;
}
h3 {
  font-size: 40px;
  margin: 10px;
}
p {
  font-size: 30px;
  text-align: center;
}
img {
  width: 500px;
  margin: 30px;
  align-self: center;
}
`

const HowTo = () => {
    return <>
      <SheetContainer >
    <h1>Postgresql How To's</h1>
    <h3>"To assist those on their path of discovery"- Ron Templeman</h3>
          <p>We are continuously adding and upgrading this site, Come back Soon!</p>
    </SheetContainer >
    <Footer />
    </> 
    
  };
  
  export default HowTo;